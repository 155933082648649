<template>
  <confirm-component type="ok-cancel" @submit="submit" @cancel="cancel" :loading="loading">
    <template #default>
      <p>Timer will be start on todays date</p>
    </template>
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useTrackStore } from '../stores/track.store';

// DTO's
import { Track } from '../dto/track';

const trackStore = useTrackStore();

const dialogRef: any = inject('dialogRef');
const track: Track = dialogRef.value.data.track;

const loading = ref(false);

async function submit() {
  loading.value = true;
  await trackStore.create({
    project: track.project.id,
    task: track.task.id,
    person: track.person.id,
    startDate: new Date().toISOString(),
    description: track.description
  });

  close(true);
}

function cancel() {
  close(false);
}

function close(saved: boolean) {
  dialogRef.value.close({ saved });
}
</script>