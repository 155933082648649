import { defineStore } from "pinia";
import {
  liveRecordsSession,
  useSurrealdbConnectionStore,
} from "./surrealdb-connection.store";
import { useCompanyStore } from "./company.store";
import { Person, PersonCreate, PersonUpdate } from "../dto/person";
import { Ref, computed, ref, watch } from "vue";
import { useAuthStore } from "./auth.store";
import { User } from "../dto/user";

export type StorePerson = Person<User> & { hoursToday: number };

export const usePersonStore = defineStore("person", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  let isPersonsLoaded = false;
  const persons = ref<StorePerson[]>([]);

  const me = computed<Person<User> | undefined>(() => {
    return persons.value.find((person) => person.user?.id === authStore.me?.id);
  });

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => companyStore.selectedCompany,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<StorePerson>(
      `
      select
        *,
        user.*,
        math::fixed(type::decimal(math::sum((SELECT duration::mins(endDate - startDate) AS hours FROM track WHERE person = $parent.id AND time::group(startDate, "day") = time::group(time::now(), "day") AND endDate != NONE).hours)) / 60, 2) AS hoursToday
      from person
      WHERE company.id = "${companyStore.selectedCompany?.id}"
    `,
      persons
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    await setLiveQuery();
    isPersonsLoaded = true;
  })();

  async function waitForPersonsToBeloaded(): Promise<void> {
    while (!isPersonsLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  async function listLive(
    reference: Ref<Person<User>[]>
  ): Promise<liveRecordsSession> {
    return await surrealdbStore.liveRecords<Person<User>>(
      `
      select
        *,
        user.*
      from person
      WHERE company.id = "${companyStore.selectedCompany?.id}"
    `,
      reference
    );
  }

  async function list(): Promise<Person[]> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.query<Person[][]>(`
      SELECT
        *
      FROM person
      WHERE
        company = ${companyId}
    `);
    return result[0] || [];
  }

  async function create(person: PersonCreate): Promise<Person> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.create<
      Person,
      PersonCreate & { company: string }
    >("person", {
      company: companyId,
      ...person,
    });

    if (result.length === 0) {
      throw new Error("Failed to create person");
    }
    return result[0];
  }

  async function update(personId: string, person: PersonUpdate): Promise<void> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }
    await surrealdbStore.db.query(
      `
      UPDATE $personId MERGE {
        ${person.name ? `name: $name,` : ""}
        ${person.defaultRate ? `defaultRate: $defaultRate,` : ""}
        user: ${person.user ? `$user` : `NONE`}
      }
    `,
      {
        personId,
        ...(person.name ? { name: person.name } : {}),
        ...(person.defaultRate ? { defaultRate: person.defaultRate } : {}),
        ...(person.user ? { user: person.user } : {}),
      }
    );
    await surrealdbStore.db.merge<Person, PersonUpdate>(personId, {
      ...person,
    });
  }

  async function remove(id: string): Promise<void> {
    await surrealdbStore.db.query(`DELETE FROM person WHERE id = $id`, { id });
  }

  async function removePersonFromCompany(
    companyId: string,
    userId: string
  ): Promise<void> {
    await surrealdbStore.db.query(
      `
      DELETE FROM person
      WHERE company = $companyId
      AND user = $userId
    `,
      { companyId, userId }
    );
  }

  async function personHasProjects(personId: string): Promise<boolean> {
    return (
      (
        await surrealdbStore.db.query<any[][]>(
          `
      SELECT * FROM project WHERE persons[*].person CONTAINS $id
    `,
          { id: personId }
        )
      )[0].length > 0
    );
  }

  function getPersonFromUser(userId: string): Person<User> | undefined {
    return persons.value.find((person) => person.user?.id === userId);
  }

  return {
    me,
    persons,
    list,
    listLive,
    create,
    update,
    remove,
    removePersonFromCompany,
    personHasProjects,
    getPersonFromUser,
    waitForPersonsToBeloaded,
  };
});
