import { defineStore } from "pinia";
import {
  liveRecordsSession,
  useSurrealdbConnectionStore,
} from "./surrealdb-connection.store";
import { Project, ProjectCreate, ProjectUpdate } from "../dto/project";
import { useCompanyStore } from "./company.store";
import { ref, watch } from "vue";
import { Track } from "../dto/track";
import { Person } from "../dto/person";
import { Task } from "../dto/task";
import { Client } from "../dto/client";

export type StoreProject = Project<Client, Task, Person>;
export type ProjectTrack = Omit<Track, "project">;
export type ProjectWithTrack = StoreProject & {
  tracks: ProjectTrack[];
};

export const useProjectStore = defineStore("project", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const companyStore = useCompanyStore();

  let isProjectsLoaded = false;
  const projects = ref<StoreProject[]>([]);

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => companyStore.selectedCompany,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<StoreProject>(
      `
      select
        *,
        client.*,
        (SELECT *, task.* FROM (SELECT tasks FROM project WHERE id = $parent.id)[0].tasks) AS tasks,
        (SELECT *, person.* FROM (SELECT persons FROM project WHERE id = $parent.id)[0].persons) AS persons
        from project
      WHERE client.company.id = "${companyStore.selectedCompany?.id}"
    `,
      projects
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    await setLiveQuery();
    isProjectsLoaded = true;
  })();

  async function waitForProjectsToBeloaded(): Promise<void> {
    while (!isProjectsLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  async function list(): Promise<Project[]> {
    const result = await surrealdbStore.db.query<Project[][]>(
      `
      select
        *
      from project
      WHERE client.company.id = $companyId
    `,
      { companyId: companyStore.selectedCompany?.id }
    );
    return result[0] || [];
  }

  async function get(code: string): Promise<StoreProject> {
    const project = await surrealdbStore.db.query<StoreProject[][]>(
      `select * from project where client.company = $companyId AND code = $code FETCH client, tasks.task, persons.person;`,
      { companyId: companyStore.selectedCompany?.id, code }
    );
    if (project.length > 0 && project[0].length > 0) {
      return project[0][0];
    }

    throw new Error("Project not found");
  }
  async function getWithTracks(code: string): Promise<ProjectWithTrack> {
    const project = await surrealdbStore.db.query<ProjectWithTrack[][]>(
      `
    select
      *,
      client.*,
      (SELECT *, task.* FROM (SELECT tasks FROM project WHERE id = $parent.id)[0].tasks) AS tasks,
      (SELECT *, person.* FROM (SELECT persons FROM project WHERE id = $parent.id)[0].persons) AS persons,
      (SELECT * FROM track WHERE project = $parent.id FETCH person, task) AS tracks
      from project
    WHERE code = $code
  `,
      {
        code,
      }
    );

    if (project.length > 0 && project[0].length > 0) {
      return project[0][0];
    }

    throw new Error("Project not found");
  }
  async function create(project: ProjectCreate): Promise<Project> {
    const result = await surrealdbStore.db.create<Project, ProjectCreate>(
      "project",
      project
    );
    if (result.length === 0) {
      throw new Error("Failed to create project");
    }
    return result[0];
  }
  async function remove(code: string): Promise<void> {
    await surrealdbStore.db.query(
      `delete from project where client.company = $companyId AND code = $code`,
      { companyId: companyStore.selectedCompany?.id, code }
    );
  }
  async function update(code: string, project: ProjectUpdate): Promise<void> {
    await surrealdbStore.db.query(
      ` 
      UPDATE project SET
        ${[
          ...(project.client ? ["client = $client"] : []),
          ...(project.name ? ["name = $name"] : []),
          ...(project.code ? ["code = $code"] : []),
          ...(project.startDate !== undefined
            ? [
                `startDate = ${
                  project.startDate !== null ? "$startDate" : "NONE"
                }`,
              ]
            : []),
          ...(project.endDate !== undefined
            ? [`endDate = ${project.endDate !== null ? "$endDate" : "NONE"}`]
            : []),
          ...(project.description !== undefined
            ? [
                `description = ${
                  project.description !== null ? "$description" : "NONE"
                }`,
              ]
            : []),
          ...(project.billableType !== undefined
            ? [
                `billableType = ${
                  project.billableType !== null ? "$billableType" : "NONE"
                }`,
              ]
            : []),
          ...(project.budgetType !== undefined
            ? [
                `budgetType = ${
                  project.budgetType !== null ? "$budgetType" : "NONE"
                }`,
              ]
            : []),
          ...(project.budgetHours !== undefined
            ? [
                `budgetHours = ${
                  project.budgetHours !== null ? "$budgetHours" : "NONE"
                }`,
              ]
            : []),
          ...(project.budgetCost !== undefined
            ? [
                `budgetCost = ${
                  project.budgetCost !== null ? "$budgetCost" : "NONE"
                }`,
              ]
            : []),
          ...(project.rate !== undefined
            ? [`rate = ${project.rate !== null ? "$rate" : "NONE"}`]
            : []),
          ...(project.tasks ? ["tasks = $tasks"] : []),
          ...(project.persons ? ["persons = $persons"] : []),
        ].join(",")}
      WHERE client.company = $companyId AND code = $code
    `,
      { ...project, companyId: companyStore.selectedCompany?.id, code }
    );
  }

  return {
    projects,
    list,
    get,
    getWithTracks,
    create,
    remove,
    update,
    waitForProjectsToBeloaded,
  };
});
