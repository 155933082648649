<template>
  <div class="grid grid-nogutter schedule">
    <div class="col-2 schedule__labels">
      <div class="schedule__labels__label" :style="{ height: `${entity.rowSpan * rowHeight}px`}" v-for="(entity, index) in entityRows" :key="`entity_${index}`">
        {{ entity.label }}
      </div>
    </div>
    <div class="col-10 schedule__content">
      <div class="grid grid-nogutter">
        <div class="col-12 flex schedule__content__header">
          <div class="schedule__content__header__unit" :style="{ width: `${columnWidth}px` }" v-for="(label, index) in labels" :key="`label_${index}`" >
            {{ label }}
          </div>
        </div>
        <div class="col-12 schedule__content__body">
          <div class="schedule__content__body__grid" :style="{ width: `${columnWidth * labels.length}px`, height: `${totalHeight}px` }" @click="clickGrid">
            <vue-draggable-resizable v-for="(unit, index) in units" :key="`unit_${index}`"
              :w="unit.width"
              :h="unit.height"
              :x="unit.x"
              :y="unit.y"
              :min-width="columnWidth"
              :parent="true"
              :grid="[columnWidth, rowHeight]"
              class="schedule__entity"
              :handles="['ml', 'mr']"
              @dragStop="(...event) => changePos(event, unit)"
              @resizeStop="(...event) => changeSize(event, unit)"
              @click.stop
              >
              <slot name="entity" :index="index" :unit="unit" />
            </vue-draggable-resizable>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { computed, defineProps, defineModel, ref, onMounted, watch, nextTick } from "vue";
import VueDraggableResizable from "vue-draggable-resizable";

export type Entity = {
  label: string;
  units: Unit[];
  [key: string]: any;
}
export type EntityArranged = {
  index: number;
  rowSpan: number;
  arrangedUnits: Unit[][];
} & Entity;

export type Unit = {
  start: number;
  end: number;
  [key: string]: any;
}
export type ArrangedUnit = {
  index: number;
} & Unit;

type RenderUnit = { x: number, y: number, width: number, height: number, entity: Omit<EntityArranged, "units"> } & Unit;

const $props = withDefaults(defineProps<{
  labels: string[];
  rowHeight?: number;
  columnWidth?: number;
}>(), {
  rowHeight: 30,
  columnWidth: 40
});

const entities = defineModel<Entity[]>({ required: true });

const entityRows = ref<EntityArranged[]>([]);
const units = ref<RenderUnit[]>([]);

onMounted(() => {
  generateEntityRowsAndUnits();
});

async function generateEntityRowsAndUnits() {
  entityRows.value = entities.value.map((entity, index) => {
    const arrangedUnits = arrangeUnits(entity.units);
    return {
      index,
      ...entity,
      arrangedUnits,
      rowSpan: arrangedUnits.length > 0 ? arrangedUnits.length : 1
    };
  });
  let returnUnits: RenderUnit[] = [];
  let rowIndex = 0;
  for (const entity of entityRows.value) {
    for (const units of entity.arrangedUnits) {
      for (const unit of units) {
        returnUnits.push({
          ...unit,
          entity,
          x: unit.start * $props.columnWidth,
          y: rowIndex * $props.rowHeight,
          width: (unit.end - unit.start) * $props.columnWidth,
          height: $props.rowHeight
        });
      }
      rowIndex++;
    }
    if(entity.arrangedUnits.length === 0) {
      rowIndex++;
    }
  }
  units.value = [];
  // Needed for Vue 3 reactivity as the array reference changes
  await nextTick();
  units.value = returnUnits;
}

const totalHeight = computed<number>(() => entityRows.value.reduce((acc, entity) => acc + entity.rowSpan, 0) * $props.rowHeight);

async function changePos(event: any, unit: RenderUnit) {
  const [x,y] = event;
  setUnitStartAndEnd(unit, x);
  moveUnitFromOneEntityToAnother(unit, y);
  await generateEntityRowsAndUnits();
}
function changeSize(event: any, unit: RenderUnit) {
  const [x,y,w,h] = event;
  setUnitStartAndEnd(unit, x, w);
  generateEntityRowsAndUnits();
}
function clickGrid(event: MouseEvent) {
  const entity = getClickedEntityRow(event.offsetY);
  const getLabelIndex = getClickedLabelIndex(event.offsetX);
  
  entity.units.push({
    start: getLabelIndex,
    end: getLabelIndex + 1
  });
  generateEntityRowsAndUnits();
}

function getClickedEntityRow(y: number): Entity {
  let rowIndex = 0;
  for (const entity of entityRows.value) {
    if (y < (rowIndex + entity.rowSpan) * $props.rowHeight) {
      return entity;
    }
    rowIndex += entity.index;
  }
  return entities.value[rowIndex];
}

function getClickedLabelIndex(x: number): number {
  return Math.floor(x / $props.columnWidth);
}

function setUnitStartAndEnd(unit: Unit, xPosition: number, width?: number): void {
  const unitInEntity = entities.value[unit.entity.index].units[unit.index];

  if(!width) {
    width = (unit.end - unit.start) * $props.columnWidth;
  }
  unitInEntity.start = Math.round(xPosition / $props.columnWidth);
  unitInEntity.end = Math.round((xPosition + width) / $props.columnWidth);
}

function getEntityBasedOnUnitYPosition(y: number): EntityArranged {
  let rowIndex = 0;
  for (const entity of entityRows.value) {
    if (y < (rowIndex + entity.rowSpan) * $props.rowHeight) {
      return entity;
    }
    rowIndex += entity.rowSpan;
  }
  return entityRows.value[entities.length - 1];
}

function moveUnitFromOneEntityToAnother(unit: RenderUnit, unitYPosition: number): void {
  const unitInEntity = entities.value[unit.entity.index].units[unit.index];

  const currentEntityIndex = unit.entity.index;
  const newEntityIndex = getEntityBasedOnUnitYPosition(unitYPosition).index;
  if (currentEntityIndex === newEntityIndex) {
    return;
  }

  entities.value = entities.value.map((entity, index) => {
    if (index === currentEntityIndex) {
      entity.units = entity.units.filter((_, index) => unit.index !== index);
    }
    if (index === newEntityIndex) {
      entity.units.push(unitInEntity);
    }
    return entity;
  });
}

function arrangeUnits(units: Unit[]): ArrangedUnit[][] {
  const arrengedUnits = units.map((unit, index) => ({ ...unit, index }));

  // Sort units based on their start times
  arrengedUnits.sort((a, b) => a.start - b.start);
  const rows: ArrangedUnit[][] = [];

  for (const unit of arrengedUnits) {
    let placed = false;

    // Try to place the unit in an existing row
    for (const row of rows) {
      if (row.length === 0 || row[row.length - 1].end <= unit.start) {
        row.push(unit);
        placed = true;
        break;
      }
    }

    // If no suitable row is found, create a new row
    if (!placed) {
      rows.push([unit]);
    }
  }

  return rows;
}

</script>
<style lang="scss" scoped>
.schedule {
  &__labels {
    padding-top: 30px;
    &__label {
      border: 1px solid #000;
    }
  }
  &__content {
    &__header {
      &__unit {
        height: 30px;
        border: 1px solid #000;
      }
    }
    &__body {
      &__grid {
        border: 1px solid #000;
      }
    }
  }
  &__entity {

  }
}
</style>