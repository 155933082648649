import { createRouter, createWebHistory } from "vue-router";

import TrackComponent from "./components/track.component.vue";
import ReportComponent from "./components/report.component.vue";
import ProjectsComponent from "./components/projects.component.vue";
import ProjectCreateComponent from "./components/project-create.component.vue";
import ProjectViewComponent from "./components/project-view.component.vue";
import ProjectViewGraphComponent from "./components/project-view-graph.component.vue";
import ProjectViewTasksComponent from "./components/project-view-tasks.component.vue";
import ProjectViewPersonsComponent from "./components/project-view-persons.component.vue";
import ProjectEditComponent from "./components/project-edit.component.vue";
import ClientsComponent from "./components/client-list.component.vue";
import TasksComponent from "./components/task-list.component.vue";
import PersonsComponent from "./components/person-list.component.vue";
import SelectCompanyComponent from "./components/select-company.component.vue";
import LoginComponent from "./components/login.component.vue";
import RegisterComponent from "./components/register.component.vue";
import ForgotPasswordComponent from "./components/forgot-password.component.vue";
import ActivateAccountComponent from "./components/activate-account.component.vue";
import NotFoundComponent from "./components/not-found.component.vue";
import SettingsComponent from "./components/settings.component.vue";
import SettingsPassword from "./components/settings-password.component.vue";
import SettingsProfile from "./components/settings-profile.component.vue";
import SettingsRemoveAccount from "./components/settings-remove-account.component.vue";
import CompanyCreateComponent from "./components/company-create.component.vue";
import CompanyComponent from "./components/company.component.vue";
import CompanySettingsComponent from "./components/company-settings.component.vue";
import CompanyDangerComponent from "./components/company-danger.component.vue";
import PlanComponent from "./components/plan.component.vue";

import * as middlewares from "./middlewares";
import { dateFormat } from "./utils";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      beforeEnter: middlewares.isAuth,
      children: [
        {
          path: "/select-company",
          name: "select-company",
          component: SelectCompanyComponent,
        },
        {
          path: "/company/create",
          name: "company.create",
          component: CompanyCreateComponent,
        },
        {
          path: "/",
          children: [
            {
              path: "/company",
              name: "company",
              component: CompanyComponent,
              children: [
                {
                  path: "/company/settings",
                  name: "company.settings",
                  component: CompanySettingsComponent,
                },
                {
                  path: "/company/clients",
                  name: "company.clients",
                  component: ClientsComponent,
                },
                {
                  path: "/company/tasks",
                  name: "company.tasks",
                  component: TasksComponent,
                },
                {
                  path: "/company/persons",
                  name: "company.persons",
                  component: PersonsComponent,
                },
                {
                  path: "/company/danger",
                  name: "company.danger",
                  component: CompanyDangerComponent,
                },
              ],
            },
            {
              path: "/track",
              redirect: `/track/${dateFormat(
                new Date(dateFormat(new Date(), "YYYY-MM-DD")),
                "YYYY-MM-DD"
              )}`,
            },
            {
              path: "/track/:date",
              name: "track",
              component: TrackComponent,
            },
            {
              path: "/plan",
              name: "plan",
              component: PlanComponent,
            },
            {
              path: "/report",
              name: "report",
              component: ReportComponent,
            },
            {
              path: "/",
              children: [
                {
                  path: "/projects",
                  name: "projects",
                  component: ProjectsComponent,
                },
                {
                  path: "/projects/create",
                  name: "projects.create",
                  component: ProjectCreateComponent,
                },
                {
                  path: "/projects/view/:code",
                  name: "projects.view",
                  component: ProjectViewComponent,
                  children: [
                    {
                      path: "/projects/view/:code/graph",
                      name: "projects.view.graph",
                      component: ProjectViewGraphComponent,
                    },
                    {
                      path: "/projects/view/:code/tasks",
                      name: "projects.view.tasks",
                      component: ProjectViewTasksComponent,
                    },
                    {
                      path: "/projects/view/:code/persons",
                      name: "projects.view.persons",
                      component: ProjectViewPersonsComponent,
                    },
                  ],
                },
                {
                  path: "/projects/edit/:code",
                  name: "projects.edit",
                  component: ProjectEditComponent,
                },
              ],
              beforeEnter: middlewares.isAdmin,
            },
          ],
          beforeEnter: middlewares.isCompanySelected,
        },
        {
          path: "/settings",
          name: "settings",
          component: SettingsComponent,
          redirect: `/settings/profile`,
          children: [
            {
              path: "profile",
              name: "settings.profile",
              component: SettingsProfile,
            },
            {
              path: "password",
              name: "settings.password",
              component: SettingsPassword,
            },
            {
              path: "remove-account",
              name: "settings.remove-account",
              component: SettingsRemoveAccount,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      beforeEnter: middlewares.isGuest,
      children: [
        {
          path: "/login",
          name: "login",
          component: LoginComponent,
        },
        {
          path: "/register",
          name: "register",
          component: RegisterComponent,
        },
        {
          path: "/forgot-password",
          name: "forgot-password",
          component: ForgotPasswordComponent,
        },
      ],
    },
    {
      path: "/activate-account",
      name: "activate-account",
      component: ActivateAccountComponent,
    },
    {
      path: "/logout",
      name: "logout",
      component: { beforeRouteEnter: middlewares.logout },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundComponent,
      props: { code: 404 },
    },
  ],
});
