<template>
  <div class="grid">
    <div class="col-12">
      <FormInputComponent label="Name" input-id="name" help="Name of client" required>
        <InputText class="w-full" id="name" v-model="clientForm.name" :disabled="saving" />
      </FormInputComponent>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { ref, computed, watch, onMounted } from 'vue';

// PrimeVue
import InputText from 'primevue/inputtext';

// Components
import FormInputComponent from './form-input.component.vue';

// DTO's
import { ClientCreate } from '../dto/client';

export type ClientForm = ClientCreate;

const saving = defineModel<boolean>("saving", { default: false});
const clientForm = defineModel<ClientForm>({ required: true});
const $emit = defineEmits(["isValid"]);

const isValid = computed(() => clientForm.value.name.trim() !== "");

watch(clientForm, () => {
  $emit("isValid", isValid.value);
}, { deep: true, immediate: true });

onMounted(() => {
  $emit("isValid", isValid.value);
});
</script>