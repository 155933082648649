<template>
  <Fieldset class="mb-5" legend="General">
    <div class="grid">
      <div class="col-12">
        <FormInputComponent label="Client" input-id="client" help="Client of project" required>
          <AutoComplete data-test="project-form__client" v-model="projectForm.client" :suggestions="clientItems" option-label="name" :delay="0" force-selection dropdown @complete="searchClients" input-class="w-full">
            <template #option="{ option }">
              <template v-if="!option.id"><i>Create new client:</i> "{{ option }}"</template>
              <template v-if="option.id">{{ option.name }}</template>
            </template>
          </AutoComplete>
        </FormInputComponent>
      </div>
      <div class="col-12 sm:col-6">
        <FormInputComponent data-test="project-form__name" label="Name" input-id="name" help="Name of project" required>
          <InputText class="w-full" id="name" v-model="projectForm.name" @blur="setProjectCode" />
        </FormInputComponent>
      </div>
      <div class="col-12 sm:col-6">
        <FormInputComponent label="Code" input-id="code" help="Code for project" :warning="isProjectCodeAlreadyUsed ? `Project code ''${projectForm.code}'' is already in use` : undefined" required>
          <InputText class="w-full" id="code" v-model="projectForm.code" :invalid="isProjectCodeAlreadyUsed" />
        </FormInputComponent>
      </div>
      <div class="col-6">
        <FormInputComponent label="From" input-id="from" help="Start date of project">
          <Calendar v-model="projectForm.from" input-id="from" :max-date="projectForm.to" locale="sv-SE" date-format="yy-mm-dd" show-button-bar />
        </FormInputComponent>
      </div> <div class="col-6">
        <FormInputComponent label="To" input-id="to" help="end date of project">
          <Calendar v-model="projectForm.to" input-id="to" :min-date="projectForm.from" locale="sv-SE" date-format="yy-mm-dd" show-button-bar />
        </FormInputComponent>
      </div>
      <div class="col-12">
        <FormInputComponent label="Description" input-id="description" help="Short description or note of project">
          <Textarea class="w-full" autoResize id="description" v-model="projectForm.description" />
        </FormInputComponent>
      </div>
    </div>
  </Fieldset>
  <Fieldset class="mb-5" legend="Billable">
    <div class="grid">
      <div class="col-12">
        <div class="flex align-items-center">
          <Checkbox v-model="projectForm.billable" input-id="billable" :binary="true" />
          <label for="billable" class="ml-2">is billable</label>
        </div>
      </div>
      <template v-if="billable">
        <div class="col-12 sm:col-6">
          <FormInputComponent label="Bill by" input-id="billableType" help="How the project is billed">
            <Dropdown class="w-full" id="billableType" v-model="projectForm.billableType" :options="billTypes" option-label="label" option-value="value" />
          </FormInputComponent>
        </div>
        <div class="col-12 sm:col-6">
          <FormInputComponent v-if="projectForm.billableType === 'project'" label="Rate" input-id="name" help="rate per hour" required>
            <InputNumber class="w-full" data-test="project-form__rate" id="name" v-model="projectForm.rate" locale="sv-SE" mode="currency" currency="SEK" suffix="/h" placeholder="kr/h" />
          </FormInputComponent>
          <div v-else class="h-full flex align-items-center align-self-center">
            <InlineMessage v-if="projectForm.billableType === 'person'" severity="warn" class="w-full">Set rate per person bellow</InlineMessage>
            <InlineMessage v-else-if="projectForm.billableType === 'task'" severity="warn" class="w-full">Set rate per task bellow</InlineMessage>
          </div>
        </div>
      </template>
    </div>
  </Fieldset>
  <Fieldset class="mb-5" legend="Budget">
    <div class="grid">
      <div class="col-12">
        <div class="flex align-items-center">
            <Checkbox data-test="project-form__has-budget" v-model="projectForm.hasBudget" input-id="hasBudget" :binary="true" />
            <label for="hasBudget" class="ml-2">Budget</label>
        </div>
      </div>
      <template v-if="hasBudget">
        <div class="col-12 sm:col-6">
          <FormInputComponent label="Budget by" input-id="budgetType" help="How the project is billed">
            <Dropdown class="w-full" id="budgetType" v-model="projectForm.budgetType" :options="budgetTypes" option-label="label" option-value="value" option-group-label="label" option-group-children="items" />
          </FormInputComponent>
        </div>
        <div class="col-12 sm:col-6">
          <FormInputComponent v-if="projectForm.budgetType === 'projectHours'" label="Total hours" input-id="budgetHours" help="Total hours for project" required>
            <InputNumber  class="w-full" id="budgetHours" v-model="projectForm.budgetHours" suffix=" h" locale="sv-SE" mode="decimal" placeholder="h" />
          </FormInputComponent>
          <FormInputComponent v-else-if="projectForm.budgetType === 'projectCost'" label="Cost" input-id="budgetCost" help="Total cost for project" required>
            <InputNumber class="w-full" id="budgetCost" v-model="projectForm.budgetCost" locale="sv-SE" mode="currency" currency="SEK" placeholder="kr" />
          </FormInputComponent>
          <div v-else class="h-full flex align-items-center align-self-center">
            <InlineMessage v-if="projectForm.budgetType === 'personHours' || projectForm.budgetType === 'personCost'" severity="warn" class="w-full">Set budget per person bellow</InlineMessage>
            <InlineMessage v-else-if="projectForm.budgetType === 'taskHours' || projectForm.budgetType === 'taskCost'" severity="warn" class="w-full">Set budget per task bellow</InlineMessage>
          </div>
        </div>
      </template>
    </div>
  </Fieldset>
  <Fieldset class="mb-5" legend="Tasks">
    <div class="grid">
      <div class="col-12">
        <DataTable :value="projectForm.tasks">
          <template #empty> 
            <div class="text-center">
              <template v-if="isBillableByTask || isBudgetByTask">
                <i class="text-red-500" v-if="isBillableByTask && isBudgetByTask">Project need at least one task with set rate and budget</i>
                <i class="text-red-500" v-else-if="isBillableByTask">Project need at least one task with set rate</i>
                <i class="text-red-500" v-else-if="isBudgetByTask">Project need at least one task with set budget</i>
              </template>
              <i v-else>Start by adding tasks</i>
            </div>
          </template>
          <Column header="Task" class="col-5">
            <template #body="{ data, index }">
              <div class="flex align-items-center gap-3">
                <Button class="w-2rem h-2rem" icon="pi pi-times" rounded severity="danger" size="small" v-tooltip.top="'Remove'" @click="projectForm.tasks.splice(index, 1)" />
                <template v-if="data.task.id">
                  {{ data.task.name }}
                </template>
                <template v-else>
                  {{ data.task }}
                </template>
              </div>
            </template>
          </Column>
          <Column class="col-3">
            <template #header>
              <template v-if="billable">
                <span>Rate</span>
                <span class="text-red-500" v-if="isBillableByTask">*</span>
              </template>
            </template>
            <template #body="{ data }">
              <template v-if="billable">
                <InputNumber v-if="projectForm.billableType === 'task'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.rate" locale="sv-SE" mode="currency" currency="SEK" suffix="/h" placeholder="kr/h" />
                <Tag v-else severity="secondary">
                  <template v-if="projectForm.billableType === 'project'">Rate is set for project</template>
                  <template v-else-if="projectForm.billableType === 'person'">Rate is set by each person</template>
                </Tag>
              </template>
            </template>
          </Column>
          <Column class="col-3">
            <template #header>
              <template v-if="hasBudget">
                <span>Budget</span>
                <span class="text-red-500" v-if="isBudgetByTask">*</span>
              </template>
            </template>
            <template #body="{ data }">
              <template v-if="hasBudget">
                <InputNumber v-if="projectForm.budgetType === 'taskHours'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.budgetHours" suffix=" h" locale="sv-SE" mode="decimal" placeholder="h" />
                <InputNumber v-else-if="projectForm.budgetType === 'taskCost'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.budgetCost" locale="sv-SE" mode="currency" currency="SEK" placeholder="kr" />
                <Tag v-else severity="secondary">
                  <template v-if="projectForm.budgetType === 'projectHours' || projectForm.budgetType === 'projectCost'">Budget is set for project</template>
                  <template v-else-if="projectForm.budgetType === 'personHours' || projectForm.budgetType === 'personCost'">Budget is set by each person</template>
                </Tag>
              </template>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="col-12">
        <AutoComplete v-model="addTask" data-test="project-form__add-task" :suggestions="taskItems" option-label="name" :delay="0" force-selection dropdown placeholder="Add task" @complete="searchTasks" @item-select="selectTask" input-class="w-full" class="w-full xl:w-auto">
          <template #option="{ option }">
            <template v-if="!option.id"><i>Create new task:</i> "{{ option }}"</template>
            <template v-if="option.id">{{ option.name }}</template>
          </template>
        </AutoComplete>
      </div>
    </div>
  </Fieldset>
  <Fieldset class="mb-5" legend="Persons">
    <div class="grid">
      <div class="col-12">
        <DataTable :value="projectForm.persons">
          <template #empty> 
            <div class="text-center">
              <template v-if="isBillableByPerson || isBudgetByPerson">
                <i class="text-red-500" v-if="isBillableByPerson && isBudgetByPerson">Project need at least one person with set rate and budget</i>
                <i class="text-red-500" v-else-if="isBillableByPerson">Project need at least one person with set rate</i>
                <i class="text-red-500" v-else-if="isBudgetByPerson">Project need at least one person with set budget</i>
              </template>
              <i v-else>Start by adding persons</i>
            </div>
          </template>
          <Column header="Person" class="col-5">
            <template #body="{ data, index }">
              <div class="flex align-items-center gap-3">
                <Button class="w-2rem h-2rem" icon="pi pi-times" rounded severity="danger" size="small" v-tooltip.top="'Remove'" @click="projectForm.persons.splice(index, 1)" />
                <template v-if="data.person.id">
                  {{ data.person.name }}
                </template>
                <template v-else>
                  {{ data.person }}
                </template>
              </div>
            </template>
          </Column>
          <Column class="col-3">
            <template #header>
              <template v-if="billable">
                <span>Rate</span>
                <span class="text-red-500" v-if="isBillableByPerson">*</span>
              </template>
            </template>
            <template #body="{ data }">
              <template v-if="billable">
                <InputNumber v-if="projectForm.billableType === 'person'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.rate" locale="sv-SE" mode="currency" currency="SEK" suffix="/h" placeholder="kr/h" />
                <Tag v-else severity="secondary">
                  <template v-if="projectForm.billableType === 'project'">Rate is set for project</template>
                  <template v-else-if="projectForm.billableType === 'task'">Rate is set by each task</template>
                </Tag>
              </template>
            </template>
          </Column>
          <Column class="col-3">
            <template #header>
              <template v-if="hasBudget">
                <span>Budget</span>
                <span class="text-red-500" v-if="isBudgetByPerson">*</span>
              </template>
            </template>
            <template #body="{ data }">
              <template v-if="hasBudget">
                <InputNumber v-if="projectForm.budgetType === 'personHours'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.budgetHours" suffix=" h" locale="sv-SE" mode="decimal" placeholder="h" />
                <InputNumber v-else-if="projectForm.budgetType === 'personCost'" :input-style="{ width: '100%' }" input-class="p-inputtext-sm" v-model="data.budgetCost" locale="sv-SE" mode="currency" currency="SEK" placeholder="kr" />
                <Tag v-else severity="secondary">
                  <template v-if="projectForm.budgetType === 'projectHours' || projectForm.budgetType === 'projectCost'">Budget is set for project</template>
                  <template v-else-if="projectForm.budgetType === 'taskHours' || projectForm.budgetType === 'taskCost'">Budget is set by each task</template>
                </Tag>
              </template>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="col-12">
        <AutoComplete v-model="addPerson" data-test="project-form__add-person" :suggestions="personItems" option-label="name" :delay="0" force-selection dropdown placeholder="Add person" @complete="searchPersons" @item-select="selectPerson" input-class="w-full" class="w-full xl:w-auto">
          <template #option="{ option }">
            <template v-if="!option.id"><i>Create new person:</i> "{{ option }}"</template>
            <template v-if="option.id">{{ option.name }}</template>
          </template>
        </AutoComplete>
      </div>
    </div>
  </Fieldset>
</template>
<script lang="ts" setup>
  // Libs
  import { computed, onMounted, ref, watch } from 'vue';

  // PrimeVue
  import InputText from 'primevue/inputtext';
  import InputNumber from 'primevue/inputnumber';
  import Dropdown from 'primevue/dropdown';
  import Calendar from 'primevue/calendar';
  import Textarea from 'primevue/textarea';
  import Checkbox from 'primevue/checkbox';
  import Fieldset from 'primevue/fieldset';
  import InlineMessage from 'primevue/inlinemessage';
  import Tag from 'primevue/tag';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete';
  import Button from "primevue/button";

  // Stores
  import { useClientStore } from '../stores/client.store';
  import { useTaskStore } from '../stores/task.store';
  import { usePersonStore } from '../stores/person.store';
  import { useProjectStore } from '../stores/project.store';

  // Components
  import FormInputComponent from './form-input.component.vue';

  // DTO's
  import { Project } from '../dto/project';
  import { Client } from '../dto/client';
  import { Task } from '../dto/task';
  import { Person } from '../dto/person';

  const clientStore = useClientStore();
  const taskStore = useTaskStore();
  const personStore = usePersonStore();
  const projectStore = useProjectStore();

  const projects = ref<Project[]>([]);
  const clients = ref<Client[]>([]);
  const tasks = ref<Task[]>([]);
  const persons = ref<Person[]>([]);

  const billTypes = ref([
    { label: "Rate per hour", value: "project" },
    { label: "Rate per task", value: "task" },
    { label: "Rate per person", value: "person" },
  ]);
  const budgetTypes = computed(() => [
    {
      label: "Hours",
      items: [
        { label: "Total hours", value: "projectHours" },
        { label: "Hours per task", value: "taskHours" },
        { label: "Hours per person", value: "personHours" },
      ]
    },
    ...(billable.value ? [{
      label: "Cost",
      items: [
        { label: "Total project cost", value: "projectCost" },
        { label: "Cost per task", value: "taskCost" },
        { label: "Cost per person", value: "personCost" },
      ]
    }] : [])
  ]);
  type projectFormClient = { id: string, name: string } | string;
  type projectFormTask = { id: string, name: string } | string;
  type projectFormPerson = { id: string, name: string } | string;
  export type ProjectForm = Omit<Project<string, string, string>, "id" | "client" | "from" | "to" | "tasks" | "persons"> & {
    client: projectFormClient,
    billable: boolean;
    hasBudget: boolean;
    from?: Date,
    to?: Date,
    tasks: (Omit<Project["tasks"][0], "task"> & { task: projectFormTask })[]
    persons: (Omit<Project["persons"][0], "person"> & { person: projectFormPerson })[]
  };

  const $props = defineProps<{ currentProjectCode?: string }>();
  const $emit = defineEmits(["isValid"]);

  const projectForm = defineModel<ProjectForm>({ required: true});

  const addTask = ref<projectFormTask>("");
  const addPerson = ref<projectFormPerson>("");

  const billable = computed<boolean>(() => projectForm.value.billable);
  const hasBudget = computed<boolean>(() => projectForm.value.hasBudget);

  const isBillableByTask = computed<boolean>(() => billable.value && projectForm.value.billableType === "task");
  const isBudgetByTask = computed<boolean>(() => hasBudget.value && (projectForm.value.budgetType === "taskHours" || projectForm.value.budgetType === "taskCost"));
  const isBillableByPerson = computed<boolean>(() => billable.value && projectForm.value.billableType === "person");
  const isBudgetByPerson = computed<boolean>(() => hasBudget.value && (projectForm.value.budgetType === "personHours" || projectForm.value.budgetType === "personCost"));
  
  const isProjectCodeAlreadyUsed = computed<boolean>(() => projects.value.some(project => project.code !== $props.currentProjectCode && project.code === projectForm.value.code));
  const isCodeValid = computed<boolean>(() => {
    return !isProjectCodeAlreadyUsed.value && /^[A-Z0-9-_]+$/.test(projectForm.value.code);
  });

  
  watch(projectForm, () => {
    if(!billable.value && projectForm.value.budgetType && ["projectCost", "personCost", "taskCost"].includes(projectForm.value.budgetType)) {
      projectForm.value.budgetType = "projectHours";
    }

    let isBillableValid = true;
    if(billable.value) {
      if(projectForm.value.billableType === "project" && !projectForm.value.rate) {
        isBillableValid = false;
      } else if(projectForm.value.billableType === "person" && projectForm.value.persons.some(person => !person.rate)) {
        isBillableValid = false;
      } else if(projectForm.value.billableType === "task" && projectForm.value.tasks.some(task => !task.rate)) {
        isBillableValid = false;
      }
    }

    let isBudgetValid = true;
    if(hasBudget.value) {
      if(projectForm.value.budgetType === "projectHours" && !projectForm.value.budgetHours) {
        isBudgetValid = false;
      } else if(projectForm.value.budgetType === "projectCost" && !projectForm.value.budgetCost) {
        isBudgetValid = false;
      } else if(projectForm.value.budgetType === "personHours" && projectForm.value.persons.some(person => !person.budgetHours)) {
        isBudgetValid = false;
      } else if(projectForm.value.budgetType === "personCost" && projectForm.value.persons.some(person => !person.budgetCost)) {
        isBudgetValid = false;
      } else if(projectForm.value.budgetType === "taskHours" && projectForm.value.tasks.some(task => !task.budgetHours)) {
        isBudgetValid = false;
      } else if(projectForm.value.budgetType === "taskCost" && projectForm.value.tasks.some(task => !task.budgetCost)) {
        isBudgetValid = false;
      }
    }

    const isValid = projectForm.value.client && projectForm.value.name.trim() && isCodeValid.value && projectForm.value.code.trim() && isBillableValid && isBudgetValid;
    $emit("isValid", isValid);
  }, { deep: true, immediate: true});

  onMounted(async () => {
    await Promise.all([loadProjects(), loadClients(), loadTasks(), loadPersons()]);
  });

  const clientItems = ref<projectFormClient[]>([]); 
  function searchClients(event: AutoCompleteCompleteEvent) {
    if(!event.query) {
      clientItems.value = clients.value.map(({ id, name }) => ({ id, name }))
    } else {
      clientItems.value = [
        ...(!clients.value.find(client => client.name.toLowerCase() === event.query.toLowerCase()) ? [event.query] : [] ),
        ...clients.value.filter(client => client.name.toLowerCase().startsWith(event.query.toLowerCase())).map(({ id, name }) => ({ id, name }))
      ];
    }
  }

  const taskItems = ref<projectFormTask[]>([]); 
  function searchTasks(event: AutoCompleteCompleteEvent) {
    if(!event.query) {
      taskItems.value = tasks.value.filter(task => !projectForm.value.tasks.find(({ task: existingTask }) => typeof existingTask !== "string" && existingTask.id === task.id )).map(({ id, name }) => ({ id, name }))
    } else {
      taskItems.value = [
        ...(!tasks.value.find(task => task.name.toLowerCase() === event.query.toLowerCase()) && !projectForm.value.tasks.find(({ task }) => (typeof task === "string" ? task : task.name).toLowerCase() === event.query.toLowerCase()) ? [event.query] : [] ),
        ...tasks.value.filter(task => task.name.toLowerCase().startsWith(event.query.toLowerCase()) && !projectForm.value.tasks.find(({ task: existingTask }) => typeof existingTask !== "string" && existingTask.id === task.id )).map(({ id, name }) => ({ id, name }))
      ];
    }
  }

  function selectTask(event: AutoCompleteItemSelectEvent) {
    projectForm.value.tasks.push({
      task: event.value
    });
    addTask.value = "";
  }

  const personItems = ref<projectFormPerson[]>([]); 
  function searchPersons(event: AutoCompleteCompleteEvent) {
    if(!event.query) {
      personItems.value = persons.value.filter(person => !projectForm.value.persons.find(({ person: existingPerson }) => typeof existingPerson !== "string" && existingPerson.id === person.id )).map(({ id, name }) => ({ id, name }))
    } else {
      personItems.value = [
      ...(!persons.value.find(person => person.name.toLowerCase() === event.query.toLowerCase()) && !projectForm.value.persons.find(({ person }) => (typeof person === "string" ? person : person.name).toLowerCase() === event.query.toLowerCase()) ? [event.query] : [] ),
        ...persons.value.filter(person => person.name.toLowerCase().startsWith(event.query.toLowerCase()) && !projectForm.value.persons.find(({ person: existingPerson }) => typeof existingPerson !== "string" && existingPerson.id === person.id )).map(({ id, name }) => ({ id, name }))
      ];
    }
  }

  function selectPerson(event: AutoCompleteItemSelectEvent) {
    projectForm.value.persons.push({
      person: event.value
    });
    addPerson.value = "";
  }

  async function loadProjects() {
    projects.value = await projectStore.list();
  }
  
  async function loadClients() {
    clients.value = await clientStore.list();
  }

  async function loadTasks() {
    tasks.value = await taskStore.list();
  }

  async function loadPersons() {
    persons.value = await personStore.list();
  }

  watch(() => projectForm.value.code, (value: string) => {
    projectForm.value.code = value.replace(/[^a-zA-Z0-9-_]/g, "").toUpperCase();
  }, {
    flush: "post"
  });

  function setProjectCode() {
    if(!projectForm.value.code) {
      projectForm.value.code = projectForm.value.name
      .replace(/ /g, "-")
      .replace(/[åäÅÄæÆáÁàÀõÕãÃâÂ]/g, "a")
      .replace(/[öÖøØóÓòÒôÔ]/g, "o")
      .replace(/[üÜúÚùÙûÛ]/g, "u")
      .replace(/[éÉèÈêÊëË]/g, "e")
      .replace(/[ßß]/g, "ss")
      .replace(/[íÍìÌîÎïÏ]/g, "i")
      .replace(/[ýÝÿŸ]/g, "y")
    }
  }

</script>