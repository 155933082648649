import { useAuthStore } from "./stores/auth.store";
import { useCompanyStore } from "./stores/company.store";

export const isAuth = (to, from, next) => {
  const authStore = useAuthStore();
  if (authStore.isAuth) {
    next();
  } else {
    next("/login");
  }
};

export const isOwner = async (to, from, next) => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();
  await companyStore.waitForCompaniesToBeloaded();
  if (authStore.isOwner) {
    next();
  } else {
    next("/track");
  }
};

export const isAdmin = async (to, from, next) => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();
  await companyStore.waitForCompaniesToBeloaded();
  if (authStore.isAdmin) {
    next();
  } else {
    next("/track");
  }
};

export const isGuest = (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isAuth) {
    next();
  } else {
    next("/me");
  }
};
export const logout = (to, from, next) => {
  const authStore = useAuthStore();
  authStore.logout();
  next("/login");
};

export const isCompanySelected = async (to, from, next) => {
  const companyStore = useCompanyStore();
  await companyStore.waitForCompaniesToBeloaded();
  if (!companyStore.selectedCompany) {
    next("/select-company");
    return;
  }
  next();
};
