<template>
  <LayoutComponent>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <h1>Create Company</h1>
        <form @submit.prevent="submit(companyForm)">
          <company-form-component
            v-model="companyForm"
            @isValid="(value) => (isValid = value)"
          />
          <div class="flex justify-content-center">
            <Button type="submit" :disabled="!isValid"> Create Company </Button>
          </div>
        </form>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";

// PrimeVue
import Button from "primevue/button";

// Components
import LayoutComponent from "./layout.component.vue";
import CompanyFormComponent, {
  CompanyForm,
} from "./company-form.component.vue";

// Stores
import { useCompanyStore } from "../stores/company.store";
import { useAuthStore } from "../stores/auth.store";
import { getRecaptcha } from "../utils";

const $router = useRouter();
const reCaptcha = useReCaptcha();

const companyStore = useCompanyStore();
const authStore = useAuthStore();

const saving = ref(false);
const isValid = ref(false);

const companyForm = ref<CompanyForm>({
  name: "",
});

async function submit(company: CompanyForm) {
  if (authStore.me === undefined) {
    throw new Error("User is not authenticated");
  }

  saving.value = true;
  const newCompany = await companyStore.create({
    captcha: await getRecaptcha(reCaptcha!),
    company: {
      name: company.name,
    },
  });

  await companyStore.reloadCompanies();
  companyStore.setSelectedCompany(newCompany.id);
  $router.replace("/projects");
}
</script>
