import { defineStore } from "pinia";
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { useCompanyStore } from "./company.store";
import { User } from "../dto/user";

export const useUserStore = defineStore("user", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const companyStore = useCompanyStore();

  async function list(): Promise<User[]> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.query<User[][]>(`
      SELECT
        *
      FROM user
    `);
    return result[0] || [];
  }

  function getUserName(user: User): string {
    return user.name || nameFromEmail(user.email);
  }

  function nameFromEmail(email: string): string {
    return email.split("@")[0];
  }

  return { list, getUserName };
});
