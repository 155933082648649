import { defineStore } from "pinia";
import {
  liveRecordsSession,
  useSurrealdbConnectionStore,
} from "./surrealdb-connection.store";
import { useCompanyStore } from "./company.store";
import { Task, TaskCreate, TaskUpdate } from "../dto/task";
import { ref, watch } from "vue";

export const useTaskStore = defineStore("task", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const companyStore = useCompanyStore();

  let isTasksLoaded = false;
  const tasks = ref<Task[]>([]);

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => companyStore.selectedCompany,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<Task>(
      `
      select
        *
      from task
      WHERE company.id = "${companyStore.selectedCompany?.id}"
    `,
      tasks
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    await setLiveQuery();
    isTasksLoaded = true;
  })();

  async function waitForTasksToBeloaded(): Promise<void> {
    while (!isTasksLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  async function list(): Promise<Task[]> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.query<Task[][]>(`
      SELECT
        *
      FROM task
      WHERE
        company = ${companyId}
    `);
    return result[0] || [];
  }

  async function create(task: TaskCreate): Promise<Task> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.create<
      Task,
      TaskCreate & { company: string }
    >("task", {
      company: companyId,
      ...task,
    });
    if (result.length === 0) {
      throw new Error("Failed to create project");
    }
    return result[0];
  }

  async function update(id: string, task: TaskUpdate): Promise<void> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    await surrealdbStore.db.merge<Task, TaskUpdate>(id, {
      ...task,
    });
  }

  async function remove(id: string): Promise<void> {
    await surrealdbStore.db.query(`DELETE FROM task WHERE id = $id`, { id });
  }

  async function taskHasProjects(taskId: string): Promise<boolean> {
    return (
      (
        await surrealdbStore.db.query<any[][]>(
          `
      SELECT * FROM project WHERE tasks[*].task CONTAINS $id
    `,
          { id: taskId }
        )
      )[0].length > 0
    );
  }

  return {
    tasks,
    list,
    create,
    update,
    remove,
    taskHasProjects,
    waitForTasksToBeloaded,
  };
});
