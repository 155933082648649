import { RouteLocationNormalizedLoaded } from "vue-router";
import { IReCaptchaComposition } from "vue-recaptcha-v3";

export function dateFormat(date: Date | string, format: string): string {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return format
    .replace("YYYY", date.getFullYear().toString())
    .replace("MM", padLeft(date.getMonth() + 1, 2, "0"))
    .replace("DD", padLeft(date.getDate(), 2, "0"))
    .replace("mm", `${date.getMonth() + 1}`)
    .replace("dd", `${date.getDate()}`)
    .replace("HH", padLeft(date.getHours(), 2, "0"))
    .replace("hh", `${date.getHours()}`)
    .replace("II", padLeft(date.getMinutes(), 2, "0"))
    .replace("ii", `${date.getMinutes()}`)
    .replace("W", `${getWeekNumberFromDate(date)}`)
    .replace("WW", `${padLeft(getWeekNumberFromDate(date), 2, "0")}`);
}

export function hoursBetweenDates(
  from: Date | string,
  to: Date | string
): number {
  if (typeof from === "string") {
    from = new Date(from);
  }
  if (typeof to === "string") {
    to = new Date(to);
  }
  return (to.getTime() - from.getTime()) / 1000 / 60 / 60;
}

export function datesBetweenDates(
  from: Date | string,
  to: Date | string
): number {
  if (typeof from === "string") {
    from = new Date(from);
  }
  if (typeof to === "string") {
    to = new Date(to);
  }
  return Math.ceil((to.getTime() - from.getTime()) / 1000 / 60 / 60 / 24);
}

export function weeksBetweenDates(
  from: Date | string,
  to: Date | string
): number {
  if (typeof from === "string") {
    from = new Date(from);
  }
  if (typeof to === "string") {
    to = new Date(to);
  }
  return Math.ceil(datesBetweenDates(from, to) / 7);
}

export function numberOfDaysInMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function swedishKronerFormat(value: number): string {
  return moneyFormat(value, { roundBy: 2, suffix: " kr" });
}

export function swedishWholeKronerFormat(value: number): string {
  return moneyFormat(value, { roundBy: 0, suffix: " kr" });
}

export function moneyFormat(
  value: number,
  {
    prefix,
    suffix,
    thousandSeparatorChar,
    decimalPointChar,
    roundBy,
  }: {
    prefix?: string;
    suffix?: string;
    thousandSeparatorChar?: string;
    decimalPointChar?: string;
    roundBy: number;
  }
): string {
  thousandSeparatorChar = thousandSeparatorChar || ",";
  decimalPointChar = decimalPointChar || ".";

  const integer = Math.floor(value);
  const decimal = Math.round((value - integer) * Math.pow(10, roundBy));
  const decimalString = padLeft(decimal.toString(), roundBy, "0");
  return (
    (prefix || "") +
    thousandSeparator(integer.toString(), thousandSeparatorChar, 3) +
    (roundBy > 0 ? decimalPointChar + decimalString : "") +
    (suffix || "")
  );
}

function thousandSeparator(
  value: string,
  separator: string,
  groupSize: number
): string {
  const parts: string[] = [];
  for (let i = value.length; i > 0; i -= groupSize) {
    parts.push(value.substring(Math.max(0, i - groupSize), i));
  }
  return parts.reverse().join(separator);
}

export function padLeft(
  value: number | string,
  length: number,
  padChar: string
): string {
  return value.toString().padStart(length, padChar);
}

export function queryStringFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.query[param];
  if (typeof value === "string") {
    return value;
  } else if (
    value instanceof Array &&
    value.length > 0 &&
    typeof value[0] === "string"
  ) {
    return value[0];
  }
}

export function paramFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.params[param];
  if (typeof value === "string") {
    return value;
  } else if (value instanceof Array && value.length > 0) {
    return value[0];
  }
}

export function getWeekNumberFromDate(date: Date): number {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

export function isToday(date: Date, today: Date): boolean {
  return dateFormat(date, "YYYY-MM-DD") === dateFormat(today, "YYYY-MM-DD");
}

export function startOfDay(date: Date): Date {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

export function endOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

export async function getRecaptcha(
  reCaptcha: IReCaptchaComposition
): Promise<string> {
  if (process.env.ENV === "local") {
    return "";
  }
  await reCaptcha?.recaptchaLoaded();
  return (await reCaptcha?.executeRecaptcha("login")) || "";
}

export function adjustValuesToSum100(values: number[]): number[] {
  // Step 1: Round each value to the nearest integer
  let roundedValues = values.map(Math.round);

  // Step 2: Calculate the current sum of the rounded values
  let currentSum = roundedValues.reduce((a, b) => a + b, 0);

  // Step 3: Calculate the difference needed to adjust the sum to 100
  let difference = 100 - currentSum;

  // Step 4: Adjust the values based on their fractional parts
  if (difference !== 0) {
    // Create an array of indices sorted by the size of the fractional part in descending order
    let fractionalParts = values.map((value, index) => ({
      index: index,
      fractional: value - Math.floor(value),
    }));

    // Sort the fractional parts in descending order
    fractionalParts.sort((a, b) => b.fractional - a.fractional);

    // Adjust the values
    for (let i = 0; i < Math.abs(difference); i++) {
      if (difference > 0) {
        roundedValues[fractionalParts[i].index]++;
      } else {
        roundedValues[fractionalParts[i].index]--;
      }
    }
  }

  return roundedValues;
}

export function nameToColor(name: string): string {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - c.length) + c;
}

export function round(value: number, decimals: number = 2): number {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}
