<template>
  <form @submit.prevent="$emit('submit')" :data-test="name ? `${name}-form`: undefined">
    <slot />
    <div class="flex justify-content-end gap-3 mt-5">
      <Button severity="secondary" :label="type === 'yes-no' ? 'No' : 'Cancel'" @click="$emit('cancel')" :disabled="loading" />
      <Button severity="danger" type="submit" :label="type === 'yes-no' ? 'Yes' : 'Ok'" icon-pos="right" :loading="loading" />
    </div>
  </form>
</template>
<script lang="ts" setup>
import Button from "primevue/button";

const $emit = defineEmits(["submit", "cancel"]);

withDefaults(defineProps<{
  name?: string;
  type?: 'yes-no' | 'ok-cancel';
}>(), {
  type: 'yes-no'
});

const loading = defineModel<boolean>("loading", { default: false });
</script>