<template>
  <form @submit.prevent="submit(personForm)">
    <PersonFormComponent v-model="personForm" v-model:saving="saving" @isValid="value => isValid = value" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" icon="pi pi-plus" label="Create" icon-pos="right" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Stores
import { usePersonStore } from '../stores/person.store';

// Components
import PersonFormComponent, { PersonForm } from './person-form.component.vue';

const dialogRef: any = inject('dialogRef');

const personStore = usePersonStore();

const saving = ref(false);
const isValid = ref(false);

const personForm = ref<PersonForm>({
  name: "",
  defaultRate: undefined
});

async function submit(person: PersonForm) {
  saving.value = true;
  await personStore.create({
      name: person.name.trim(),
      ...(person.defaultRate ? { defaultRate: person.defaultRate } : {}),
      ...(personForm.value.user ? { user: personForm.value.user } : {}),
    });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>