<template>
  <div class="grid">
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <FormInputComponent label="Name" input-id="name" help="Name of task" required>
        <InputText class="w-full" id="name" v-model="taskForm.name" :disabled="saving" />
      </FormInputComponent>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <FormInputComponent label="Rate" input-id="rate" help="Default hourly rate">
        <InputNumber class="w-full" id="rate" v-model="taskForm.defaultRate" locale="sv-SE" mode="currency" currency="SEK" :disabled="saving" />
      </FormInputComponent>
    </div>
  </div>
</template>
<script lang="ts" setup>
  // Libs
  import { computed, onMounted, watch } from 'vue';

  // PrimeVue
  import InputText from 'primevue/inputtext';
  import InputNumber from "primevue/inputnumber";

  // Components
  import FormInputComponent from './form-input.component.vue';

  // DTO's
  import { TaskCreate } from '../dto/task';

  export type TaskForm = TaskCreate

  const $emits = defineEmits(["isValid"]);

  const saving = defineModel<boolean>("saving", { default: false });
  const taskForm = defineModel<TaskForm>({ required: true });

  const isValid = computed<boolean>(() => taskForm.value.name.trim() !== "");

  watch(taskForm, () => {
    $emits("isValid", isValid.value);
  }, { deep: true, immediate: true });

  onMounted(() => {
    $emits("isValid", isValid.value);
  });
</script>