<template>
  <div class="flex justify-content-end align-items-center mb-3">
    <Button label="Create" @click="createTask" />
  </div>
  <DataTable :value="taskStore.tasks">
    <Column field="name" header="Name" />
    <Column field="defaultRate" header="Rate">
      <template #body="{ data }">
        <template v-if="data.defaultRate">
          {{ swedishKronerFormat(data.defaultRate) }}/h
        </template>
      </template>
    </Column>
    <Column body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
      <template #body="{ data }">
        <ellipsis-resource-menu-component :items="[
          { label: 'Edit', icon: 'pi pi-pencil', action: () => editTask(data) },
          { label: 'Delete', icon: 'pi pi-trash', action: () => removeTask(data) }
        ]" />
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts" setup>

// PrimeVue
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';

// Stores
import { useTaskStore } from "../stores/task.store";

// Components
import TaskCreateComponent from "./task-create.component.vue"
import TaskEditComponent from "./task-edit.component.vue"
import TaskRemoveComponent from "./task-remove.component.vue"
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';

// DTO's
import { Task } from '../dto/task';

// Utils
import { swedishKronerFormat } from "../utils";

const dialog = useDialog();
const taskStore = useTaskStore();

function createTask(): void {
  dialog.open(TaskCreateComponent, {
    props: {
      header: "Create Task",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

function editTask(task: Task): void {
  dialog.open(TaskEditComponent, {
    data: {
      task
    },
    props: {
      header: "Edit Task",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

function removeTask(task: Task) {
  dialog.open(TaskRemoveComponent, {
    data: {
      task
    },
    props: {
      header: "Delete Task",
      contentStyle: {
        padding: "1rem 2rem",
      },
      style: {
        width: "25vw",
        padding: "0px",
        margin: "0px"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}
</script>
