<template>
  <form @submit.prevent="submit(clientForm)">
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <client-form-component v-model="clientForm" @isValid="value => isValid = value" />
    </div>
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving" label="Cancel" />
      <Button icon="pi pi-plus" icon-pos="right" type="submit" label="Create" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import ClientFormComponent, { ClientForm } from './client-form.component.vue';

// Stores
import { useClientStore } from '../stores/client.store';

const dialogRef: any = inject('dialogRef');

const clientStore = useClientStore();

const saving = ref(false);
const isValid = ref(false);

const clientForm = ref<ClientForm>({
  name: "",
});

async function submit(client: ClientForm) {
  saving.value = true;
  await clientStore.create({
    name: client.name
  });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}

</script>