<template>
  <confirm-component @submit="submit" @cancel="cancel" v-model:loading="loading">
    Are you sure you want to demote user {{ user.email }} to role "user"?
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// Components
import confirmComponent from './confirm.component.vue';

// DTO
import { User } from '../dto/user';
import { useCompanyStore } from '../stores/company.store';

const dialogRef: any = inject('dialogRef');

const companyStore = useCompanyStore();

const user: User = dialogRef.value.data.user;

const loading = ref(false);

async function submit() {
  if(!companyStore.selectedCompany) {
    return;
  }

  loading.value = true;

  await companyStore.updateUser(companyStore.selectedCompany.id, user.id, "user");

  companyStore.reloadCompanies();

  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>