
<template>
  {{ duration }}
</template>
<script lang="ts" setup>
// Libs
import { computed, onMounted, onUnmounted, ref } from "vue";
import { padLeft } from "../utils";

const $props = defineProps<{
  startDate: Date;
  endDate?: Date;
}>();

const duration = ref<string>();
const isLive = computed(() => $props.endDate === undefined);

let timer: NodeJS.Timeout | number | undefined;

onMounted(() => {
  duration.value = setDuration($props.startDate, $props.endDate);
  if(isLive.value) {
    timer = setInterval(() => {
      duration.value = setDuration($props.startDate, $props.endDate);
    }, 1000);
  }
});

onUnmounted(() => {
  clearInterval(timer);
});

function setDuration(startDate: Date, endDate?: Date): string {
  const diff = (endDate ? endDate : new Date()).getTime() - startDate.getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = padLeft(Math.floor(diff / 1000 / 60) % 60, 2, '0');

  return `${hours}:${minutes}`;
}
</script>
