import BaseStyle from 'primevue/base/style';

var classes = {
  root: function root(_ref) {
    var props = _ref.props;
    return ['p-fieldset p-component', {
      'p-fieldset-toggleable': props.toggleable
    }];
  },
  legend: 'p-fieldset-legend',
  legendtitle: 'p-fieldset-legend-text',
  togglericon: 'p-fieldset-toggler',
  toggleablecontent: 'p-toggleable-content',
  content: 'p-fieldset-content'
};
var FieldsetStyle = BaseStyle.extend({
  name: 'fieldset',
  classes: classes
});

export { FieldsetStyle as default };
