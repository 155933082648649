<template>
  {{ trackTotal }}
  <i v-if="showIcon" class="pi pi-clock" />
</template>
<script lang="ts" setup>
// Libs
import { computed, ref } from 'vue';

// DTO's
import { Track } from '../dto/track';

// Utils
import { endOfDay, padLeft, startOfDay } from '../utils';

const $props = withDefaults(defineProps<{
  tracks: Track[];
  date: Date;
  showIcon?: boolean
}>(), {
  showIcon: true
});

const filteredTracks = computed(() => {
  return $props.tracks.filter(track => {
    const startDate = new Date(track.startDate);
    return startDate >= startOfDay($props.date) && startDate <= endOfDay($props.date);
  });
});

const trackTotal = computed<string>(() => {
  const duration = filteredTracks.value.reduce((acc, track) => acc + (track.endDate ? new Date(track.endDate).getTime() - new Date(track.startDate).getTime() : 0), 0);
  const hours = Math.floor(duration / 1000 / 60 / 60);
  const minutes = padLeft(Math.floor(duration / 1000 / 60) % 60, 2, '0');

  return `${hours}:${minutes}`;
});
</script>