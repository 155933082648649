<template>
  <div class="flex flex-column gap-2">
    <label :for="inputId">
      {{ label }}
      <template v-if="required">
        <span class="text-red-500">*</span>
      </template>
    </label>
    <slot name="default" />
    <small v-if="help && !warning">{{ help }}</small>
    <small v-else-if="warning" class="text-red-500">
      <slot name="warning">{{  warning }}</slot>
    </small>
  </div>
</template>
<script lang="ts" setup>
  // Libs
  import { withDefaults } from 'vue';

  withDefaults(defineProps<{
    label: string;
    inputId: string;
    required?: true;
    help?: string;
    warning?: string | boolean;
  }>(), {});
</script>