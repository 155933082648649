import { defineStore } from "pinia";
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { Track, TrackCreate, TrackUpdate } from "../dto/track";
import { useAuthStore } from "./auth.store";
import { Ref } from "vue";
import { useCompanyStore } from "./company.store";

export const useTrackStore = defineStore("track", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  async function list(
    fromDate: Date,
    toDate: Date,
    viewFor: "me" | "all",
    reference: Ref<Track[]>
  ) {
    return await surrealdbStore.liveRecords<Track>(
      `
      select
        *,
        person.*,
        project.*,
        project.client.*,
        task.*
      from track
      WHERE
        project.client.company = "${companyStore.selectedCompany?.id}" AND
        ${viewFor === "me" ? `person.user = "${authStore.me?.id}" AND` : ""}
        startDate >= "${fromDate.toISOString()}" AND
        startDate <= "${toDate.toISOString()}"
    `,
      reference
    );
  }

  async function filter({
    from,
    to,
    projects,
    tasks,
    persons,
  }: {
    from: Date;
    to: Date;
    projects: string[];
    tasks: string[];
    persons: string[];
  }): Promise<Track[]> {
    if (projects.length === 0 || tasks.length === 0 || persons.length === 0) {
      return [];
    }

    return (
      await surrealdbStore.db.query<Track[][]>(
        `
        select
          *,
          person.*,
          project.*,
          project.client.*,
          task.*
        from track
        WHERE
        ${[
          `project.client.company.id = "${companyStore.selectedCompany?.id}"`,
          `startDate >= "${from.toISOString()}"`,
          `startDate <= "${to.toISOString()}"`,
          `project.id INSIDE [${projects.map((p) => `"${p}"`).join(", ")}]`,
          `task.id INSIDE [${tasks.map((t) => `"${t}"`).join(", ")}]`,
          `person.id INSIDE [${persons.map((p) => `"${p}"`).join(", ")}]`,
        ].join(" AND ")}
      `
      )
    )[0];
  }

  async function create(track: TrackCreate) {
    await surrealdbStore.db.create<Track, TrackCreate>("track", track);
  }

  async function update(trackId: string, track: TrackUpdate) {
    await surrealdbStore.db.merge<Track, TrackUpdate>(trackId, track);
  }

  async function remove(trackId: string) {
    await surrealdbStore.db.query(`
      delete from track
      where id = "${trackId}"
    `);
  }

  async function stop(track: Track): Promise<void> {
    await surrealdbStore.db.query(
      `
      update track
      set endDate = "${new Date().toISOString()}"
      where id = "${track.id}"
    `
    );
  }

  return { list, filter, create, update, remove, stop };
});
