<template>
  <layout-component>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <Panel>
          <template #header>Forgotten password</template>
          <Message v-if="success" @close="success = false">
            Reset link sent successfully, please check your email
          </Message>
          <form v-else @submit.prevent="submit">
            <p>Don't let forgotten password keep you up at night. Type your account email and click "send reset link" and we'll send you a reset link right away!</p>
            <div class="field">
              <label for="email">Email</label>
              <InputText ref="emailInput" :disabled="loading" type="email" name="email" :class="['w-full', { 'p-invalid': invalid }]" id="email" v-model="form.email" />
            </div>
            <Message v-if="invalid" severity="error" v-on:close="invalid = false">
              <template v-if="invalid === 'email_not_found'">
                User with email could not be found.
                <router-link :to="`/register?email=${form.email}`">
                  Click here to register
                </router-link>
              </template>
              <template v-else>
                Something went wrong, please try again
              </template>
            </Message>
            <div class="flex justify-content-center">
              <Button type="submit" :disabled="loading || !isValid">
                <template v-if="!loading">
                  Send reset link
                </template>
                <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
              </Button>
            </div>
            <div class="flex">
              <div class="flex-grow-1 text-right flex gap-1 justify-content-end align-items-end">
                <router-link to="/login">
                  Login
                </router-link>
                |
                <router-link to="/register">
                  Register
                </router-link>
              </div>
            </div>
          </form>
        </Panel>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useReCaptcha } from 'vue-recaptcha-v3';

import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

import LayoutComponent from './layout.component.vue';

import { useAuthStore } from '../stores/auth.store';
import { getRecaptcha, queryStringFromRoute } from '../utils';
import { onMounted } from 'vue';

const route = useRoute();
const reCaptcha = useReCaptcha();

const loading = ref(false);
const invalid = ref<boolean | 'email_not_found'>(false);
const success = ref(false);

const emailInput = ref<HTMLInputElement | null>(null);

const form = ref({
  email: ''
});

const authStore = useAuthStore();

const isValid = computed(() => {
  return form.value.email.length > 0;
});

const emailQuery = queryStringFromRoute(route, 'email');

onMounted(() => {
  (emailInput.value as any)?.$el.focus();
  if(emailQuery) {
    form.value.email = emailQuery;
  }
});

async function submit() {
  loading.value = true;
  
  // Get captcha code
  const captchaCode = await getRecaptcha(reCaptcha!);
  try {
    await authStore.initResetPassword(captchaCode, form.value.email);
    success.value = true;
  } catch (error) {
    if(error.message === 'User not found') {
      invalid.value = 'email_not_found';
    } else {
      invalid.value = true;
    }
  }
  loading.value = false;
}

</script>