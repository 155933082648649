<template>
  <track-item-component :view-for="viewFor" v-for="(track, index) of tracksSorted" :key="`track_${index}`" :track="track" />
  <div class="grid grid-nogutter track-item py-3" v-if="tracks.length > 0">
    <div class="col-2 mt-2"></div>
    <div class="col-6 mt-2 text-right">Total:</div>
    <div class="col-1 text-right mt-2">
      <track-total-duration-component :date="date" :tracks="tracks" />
    </div>
  </div>
  <div v-else class="track-list__empty flex justify-content-center align-items-center">
    <i>No tracks for this day</i>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { Track } from '../dto/track';

// Components
import TrackItemComponent from './track-item.component.vue';
import TrackTotalDurationComponent from './track-total-duration.component.vue';

// Stores
import { useTrackStore } from '../stores/track.store';
import { liveRecordsSession } from '../stores/surrealdb-connection.store';
import { endOfDay, startOfDay } from '../utils';

const trackStore = useTrackStore();

const $props = defineProps<{
  viewFor: 'me' | 'all';
  date: Date;
}>();

const tracks = ref<Track[]>([]);
const tracksSorted = computed<Track[]>(() => [ ...tracks.value ].sort((a, b) => a.startDate > b.startDate ? 1 : -1));

let listner: liveRecordsSession | undefined;

onMounted(async () => {
  listner = await trackStore.list(startOfDay($props.date), endOfDay($props.date), $props.viewFor, tracks)
});

onUnmounted(() => {
  if(listner) {
    listner.end();
  }
});

</script>
<style lang="scss" scoped>
  .track-list__empty {
    height: 300px;
    background-color: var(--surface-300);
    color: var(--surface-500);
  }
</style>