<template>
  <form @submit.prevent="submit(trackForm)">
    <track-form-component v-model="trackForm" @isValid="validate" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" icon="pi pi-plus" :label="saveButtonLabel" icon-pos="right" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref, computed } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import TrackFormComponent, { TrackForm } from './track-form.component.vue';

// Stores
import { useTrackStore } from '../stores/track.store';
import { usePersonStore } from '../stores/person.store';
import { useAuthStore } from '../stores/auth.store';

const trackStore = useTrackStore();
const personStore = usePersonStore();
const authStore = useAuthStore();

const dialogRef: any = inject('dialogRef');
const date = dialogRef.value.data.date;

const trackForm = ref<TrackForm>({
  project: "",
  task: "",
  person: "",
  date: new Date(),
  description: "",
  start: "",
  end: ""
});

const isValid = ref(false);
const saving = ref(false);

const saveButtonLabel = computed(() => trackForm.value.end ? "Create" : "Start");

function validate(formIsValid: boolean) {
  isValid.value = formIsValid;
}

async function submit(track: TrackForm) {
  const startDate = track.start !== "" ? dateAndTimeToDate(date, track.start) : new Date();
  const endDate = track.end !== "" ? dateAndTimeToDate(date, track.end) : undefined;

  if(endDate && endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  await trackStore.create({
    project: track.project,
    task: track.task,
    person: authStore.isAdmin ? track.person : personStore.me!.id,
    startDate: startDate.toISOString(),
    ...(endDate !== undefined ? { endDate: endDate.toISOString() } : {}),
    description: track.description
  });

  close();
}

function cancel() {
  close();
}
function close() {
  dialogRef.value.close();
}

function dateAndTimeToDate(date: Date, time: string): Date {
  const [hours, minutes] = time.split(":").map(Number);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
}

</script>