<template>
  <div class="grid">
    <div class="col-12">
      <FormInputComponent label="Name" input-id="name" help="Name of company" required>
        <InputText class="w-full" id="name" v-model="companyForm.name" :disabled="saving" />
      </FormInputComponent>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { computed, watch, onMounted } from 'vue';

// PrimeVue
import InputText from 'primevue/inputtext';

// Components
import FormInputComponent from './form-input.component.vue';

// DTO's
import { CompanyCreate } from '../dto/company';

export type CompanyForm = Omit<CompanyCreate, "users">;

const saving = defineModel<boolean>("saving", { default: false});
const companyForm = defineModel<CompanyForm>({ required: true});
const $emit = defineEmits(["isValid"]);

const isValid = computed(() => companyForm.value.name.trim() !== "");

watch(companyForm, () => {
  $emit("isValid", isValid.value);
}, { deep: true, immediate: true });

onMounted(() => {
  $emit("isValid", isValid.value);
});
</script>