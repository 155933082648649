<template>
    <layout-component>
      <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2">
        <div class="flex justify-content-between align-items-center mb-3">
          <h1>Report</h1>
        </div>
        <div>
          <Calendar v-model="form.from" :max-date="form.to" date-format="yy-mm-dd" show-button-bar />
          <Calendar v-model="form.to" :min-date="form.from" date-format="yy-mm-dd" show-button-bar />
          <MultiSelect v-model="form.clients" display="chip" :options="clientStore.clients" option-label="name" option-value="id" placeholder="Select Client"
            filter class="w-full md:w-20rem" />
            <MultiSelect v-model="form.projects" display="chip" :options="projectStore.projects" option-label="name" option-value="id" placeholder="Select Projects"
            filter class="w-full md:w-20rem" />
          <MultiSelect v-model="form.tasks" display="chip" :options="taskStore.tasks" option-label="name" option-value="id" placeholder="Select Tasks"
            filter class="w-full md:w-20rem" />
          <MultiSelect v-model="form.persons" display="chip" :options="personStore.persons" option-label="name" option-value="id" placeholder="Select Person"
            filter class="w-full md:w-20rem" />
        </div>
        <Card>
          <template #content>
            <TabMenu v-model:activeIndex="activeTab" :model="tabItems">
              <template #item="{ item, props }">
                <router-link v-slot="{ href, navigate }" :to="item.route" custom>
                  <a :href="href" v-bind="props.action" @click="navigate">
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ item.label }}</span>
                  </a>
                </router-link>
              </template>
            </TabMenu>
            <template v-if="['tasks', 'persons'].includes(selectedTab)">
              <report-group-component :dimensions="selectedDimensions" :tracks="tracks" :clients="selectedClients" :projects="selectedProjects" :tasks="selectedTasks" :persons="selectedPersons" />
            </template>
            <template v-else-if="selectedTab === 'list'">
              <report-list-component :tracks="tracks" />
            </template>
          </template>
        </Card>
      </div>
    </div>
    </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

// PrimeVue
import { MenuItem } from 'primevue/menuitem';
import TabMenu from 'primevue/tabmenu';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';

// Components
import LayoutComponent from './layout.component.vue';
import ReportGroupComponent from './report-group.component.vue';
import ReportListComponent from './report-list.component.vue';

// Stores
import { usePersonStore } from '../stores/person.store';
import { useProjectStore } from '../stores/project.store';
import { useTaskStore } from '../stores/task.store';
import { useTrackStore } from '../stores/track.store';
import { useClientStore } from '../stores/client.store';

// DTO's
import { Track } from '../dto/track';

// Utils
import { queryStringFromRoute } from '../utils';

const $route = useRoute();

const clientStore = useClientStore();
const projectStore = useProjectStore();
const taskStore = useTaskStore();
const personStore = usePersonStore();
const trackStore = useTrackStore();

const tracks = ref<Track[]>([]);

const activeTab = ref(0);

const selectedTab = computed(() => queryStringFromRoute($route, "view"));

const selectedDimensions = computed(() => {
  if(selectedTab.value === "tasks") {
    return ['task', 'person', ...(selectedProjects.value.length > 1 ? ['project'] : [])];
  } else if (selectedTab.value === "persons") {
    return ['person', 'task', ...(selectedProjects.value.length > 1 ? ['project'] : [])];
  }

  throw new Error("Incorrect tab selected");
})

const selectedClients = computed(() => form.value.clients.map((id: string) => clientStore.clients.find((client) => client.id === id)));
const selectedProjects = computed(() => form.value.projects.map((id: string) => projectStore.projects.find((project) => project.id === id)));
const selectedTasks = computed(() => form.value.tasks.map((id: string) => taskStore.tasks.find((task) => task.id === id)));
const selectedPersons = computed(() => form.value.persons.map((id: string) => personStore.persons.find((person) => person.id === id)));

const form = ref<{
  from: Date;
  to: Date;
  clients: string[];
  projects: string[];
  tasks: string[];
  persons: string[];
}>({
  from: firstDateOfMonth(new Date()),
  to: new Date(),
  clients: [],
  projects: [],
  tasks: [],
  persons: [],
});

const tabItems = ref<MenuItem[]>([
  { label: 'Graph', icon: 'fa-solid fa-chart-line', route: { name: 'report', query: { view: 'graph' } } },
  { label: 'Tasks', icon: 'fa-solid fa-list-check', route: { name: 'report', query: { view: 'tasks' } } },
  { label: 'Persons', icon: 'fa-solid fa-user-tie', route: { name: 'report', query: { view: 'persons' } } },
  { label: 'List', icon: 'fa-solid fa-list', route: { name: 'report', query: { view: 'list' } } },
]);

watch(form, async () => {
  await getTracks();
}, { deep: true });
watch(selectedTab, () => {
  setActiveTab();
})

onMounted(async () => {
  setActiveTab();

  await projectStore.waitForProjectsToBeloaded();
  await taskStore.waitForTasksToBeloaded();
  await personStore.waitForPersonsToBeloaded();

  form.value.clients = clientStore.clients.map((client) => client.id);
  form.value.projects = projectStore.projects.map((project) => project.id);
  form.value.tasks = taskStore.tasks.map((task) => task.id);
  form.value.persons = personStore.persons.map((person) => person.id);
});

async function getTracks(): Promise<void> {
  tracks.value = await trackStore.filter({
    from: form.value.from,
    to: form.value.to,
    projects: form.value.projects,
    tasks: form.value.tasks,
    persons: form.value.persons,
  });
}
function setActiveTab(): void {
  activeTab.value = tabItems.value.findIndex((item) => item.route.query?.view === selectedTab.value);
}

function firstDateOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}


</script>