
<template>
  <div :class="['grid grid-nogutter track-item py-3', { 'track-item--active': trackIsActive(track) }]">
    <div class="col-2 mt-2 pl-3">
      <div>
        {{ dateFormat(track.startDate, 'HH:II') }}
        <template v-if="track.endDate">
          - {{ dateFormat(track.endDate, 'HH:II') }}
        </template>
      </div>
      <div v-if="viewFor === 'all'">
        <span class="text-400">
          <i>
            {{ track.person.name }}
          </i>
          <template v-if="track.person.user === authStore.me?.id">
            (<b class="text-primary-800 underline">you</b>)
          </template>
        </span>
      </div>
    </div>
    <div class="col-6 mt-2">
      <p class="m-0"><b>{{ track.project?.name }}</b> - <i>{{ track.project?.client?.name }}</i></p>
      <p class="track-item__task m-0">{{ track.task?.name }}</p>
      <p class="track-item__description">{{ track.description }}</p>
    </div>
    <div class="col-1 text-right mt-2">
      <track-duration-component :key="`${track.startDate}-${track.endDate ? track.endDate : ''}`" :start-date="new Date(track.startDate)" :end-date="track.endDate ? new Date(track.endDate) : undefined" />
    </div>
    <div class="col-3 text-right mt-0 pr-3 flex gap-3 justify-content-end align-items-start">
      <template v-if="trackIsActive(track)">
        <Button severity="contrast" icon="pi pi-clock" label="Stop" @click="stopTrack(track)" />
      </template>
      <template v-else>
        <Button severity="secondary" icon="pi pi-clock" label="Start" @click="startTrack(track)" />
      </template>
      <ellipsis-resource-menu-component :items="[
          { label: 'Edit', icon: 'pi pi-pencil', action: () => editTrack(track) },
          { label: 'Delete', icon: 'pi pi-trash', action: () => removeTrack(track) }
        ]" />
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { useRouter } from "vue-router";

// PrimeVue
import Button from "primevue/button";
import { useDialog } from "primevue/usedialog";

// Components
import TrackDurationComponent from "./track-duration.component.vue";
import EllipsisResourceMenuComponent from "./ellipsis-resource-menu.component.vue";
import TrackRemoveComponent from "./track-remove.component.vue";
import TrackEditComponent from "./track-edit.component.vue";
import TrackStartTodayComponent from "./track-start-today.component.vue";

// Stores
import { useTrackStore } from "../stores/track.store";

// DTO's
import { Track } from "../dto/track";

// Utils
import { dateFormat, isToday } from "../utils";
import { openDialog } from "../dialog";
import { useAuthStore } from "../stores/auth.store";

const trackStore = useTrackStore();
const authStore = useAuthStore();

const dialog = useDialog();
const $router  = useRouter();

defineProps<{
  track: Track;
  viewFor: 'me' | 'all'
}>();

function trackIsActive(track: Track): boolean {
  return track.endDate === undefined;
}

function editTrack(track: Track) {
  openDialog({
    dialog,
    component: TrackEditComponent,
    header: "Edit Track",
    data: { track }
  });
}

function removeTrack(track: Track) {
  openDialog({
    dialog,
    component: TrackRemoveComponent,
    header: "Delete Track",
    data: { track }
  });
}

async function startTrack(track: Track) {
  if(!isToday(new Date(track.startDate), new Date())) {
    openDialog({
      dialog,
      component: TrackStartTodayComponent,
      header: "Start today?",
      data: { track },
      onClose: options => {
        if(options?.data.saved) {
          $router.push({ name: 'track', params: { date: dateFormat(new Date(), "YYYY-MM-DD") } })
        }
      }
    });
  } else {
    await trackStore.create({
      project: track.project.id,
      task: track.task.id,
      person: track.person.id,
      startDate: new Date().toISOString(),
      description: track.description
    });
  }
}

function stopTrack(track: Track) {
  trackStore.stop(track);
}

</script>
<style lang="scss" scoped>
.track-item {
  &__description {
    font-size: 0.8rem;
  }
  border-bottom: 1px solid #e0e0e0;
  &--active {
    background-color: #fff5ec;
  }
}
</style>