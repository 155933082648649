<template>
  <layout-component>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2">
        <TabMenu :model="menuItems">
          <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
              <a :href="href" v-bind="props.action" @click="navigate">
                <span v-bind="props.icon" />
                <span v-bind="props.label">{{ item.label }}</span>
              </a>
            </router-link>
            </template>
        </TabMenu>
        <router-view />
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed } from 'vue';

// Prime Vue
import TabMenu from 'primevue/tabmenu';
import { MenuItem } from 'primevue/menuitem';

// Components
import LayoutComponent from './layout.component.vue';
import { useCompanyStore } from '../stores/company.store';

const companyStore = useCompanyStore();

const menuItems = computed<MenuItem[]>(() => [
    { label: 'Profile', route: "/settings/profile" },
    { label: 'Password', route: "/settings/password" },
    { label: 'Delete acocunt', route: "/settings/remove-account" },
  ])
</script>