<template>
  <div class="grid">
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <form-input-component label="Project" input-id="project" required>
        <Dropdown v-model="trackForm.project" :options="projects" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" optionValue="value" />
      </form-input-component>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <form-input-component label="Task" input-id="task" required>
        <Dropdown v-model="trackForm.task" :options="tasks" optionLabel="label" optionValue="value" empty-message="Select project first" />
      </form-input-component>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3" v-if="authStore.isAdmin">
      <form-input-component label="Person" input-id="person" required>
        <Dropdown v-model="trackForm.person" :options="persons" optionLabel="label" optionValue="value" empty-message="Select project first">
          <template #value="{ value }">
              {{ persons.find(person => person.value === value)?.label || "&nbsp;" }}
              <template v-if=" persons.find(person => person.value === value)?.isMe">
                <i class="text-400">(you)</i>
              </template>
          </template>
          <template #option="{ option }">
            <div class="flex justify-content-between">
              {{ option.label }}
              <template v-if="option.isMe">
                &nbsp;
                <i class="text-400">(you)</i>
              </template>
            </div>
          </template>
          </Dropdown>
      </form-input-component>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <form-input-component label="Notes" input-id="description">
        <Textarea v-model="trackForm.description" class="w-full" placeholder="Notes" rows="5" autoResize />
      </form-input-component>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <div class="grid">
        <div class="col-6">
          <form-input-component label="Start" input-id="start">
            <InputText v-model="trackForm.start" class="w-full" placeholder="Start time" @change="formatTime('start')" />
          </form-input-component>
        </div>
        <div class="col-6">
          <form-input-component label="End" input-id="end">
            <InputText v-model="trackForm.end" class="w-full" placeholder="End time" @change="formatTime('end')" />
          </form-input-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { computed, watch, onMounted } from 'vue';

// PrimeVue
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';

// Components
import FormInputComponent from './form-input.component.vue';

// Stores
import { useProjectStore } from '../stores/project.store';
import { useAuthStore } from '../stores/auth.store';
import { useClientStore } from '../stores/client.store';

// DTO's
import { Track } from "../dto/track";

// Utils
import { padLeft } from '../utils';

export type TrackForm = Omit<Track, 'id' | 'project' | 'task' | 'person' | 'startDate' | 'endDate'> & {
  project: string;
  task: string;
  person: string;
  date: Date;
  start: string;
  end: string;
};

type option = { label: string, value: string };
type personOption = option & { isMe?: true };
type groupOption = { label: string, items: option[] };

const $emits = defineEmits(["isValid"]);

const projects = computed<groupOption[]>(() => clientStore.clients.map(client => ({
  label: client.name,
  items: projectStore.projects.filter(project => project.client.id === client.id).map(project => ({ label: project.name, value: project.id }))
})).filter(group => group.items.length > 0));
const tasks = computed<option[]>(() => projectStore.projects.find(project => project.id === trackForm.value.project)?.tasks.map(task => ({ label: task.task.name, value: task.task.id })) || []);
const persons = computed<personOption[]>(() => projectStore.projects.find(project => project.id === trackForm.value.project)?.persons.map(person => (
  {
    label: person.person.name,
    value: person.person.id,
    ...(person.person.user === authStore.me?.id ? { isMe: true } : {})
  }
)) || []);

const trackForm = defineModel<TrackForm>({ required: true });

const isValid = computed<boolean>(() => {
  if(trackForm.value.project === "" || trackForm.value.task === "" || trackForm.value.person === "") {
    return false;
  }

  if(trackForm.value.start !== "" && !isTimeValid(trackForm.value.start)) {
    return false;
  }

  if(trackForm.value.end !== "" && !isTimeValid(trackForm.value.end)) {
    return false;
  }

  if(trackForm.value.end !== "" && trackForm.value.start === "") {
    return false;
  }

  return true;
});

const projectStore = useProjectStore();
const clientStore = useClientStore();
const authStore = useAuthStore();

watch(trackForm, () => {
  $emits("isValid", isValid.value);
}, { deep: true, immediate: true });

watch(() => trackForm.value.project, () => {
  trackForm.value.task = "";

  const selectedProject = projectStore.projects.find(project => project.id === trackForm.value.project);
  if(selectedProject) {
    trackForm.value.person = selectedProject.persons.find(person => person.person.user === authStore.me?.id)?.person.id || "";
  }
});

onMounted(() => {
  $emits("isValid", isValid.value);
});

function formatTime(time: 'start' | 'end') {
  trackForm.value[time] = parseTime(trackForm.value[time]);
}

function parseTime(input: string): string {
  input = input.trim();
  if(input === "") return "";

  const split = input.split(':');
  if(split.length === 2) {
    const hour = parseInt(split[0]);
    const minute = parseInt(split[1]);

    if(hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
      return `${hour}:${padLeft(minute, 2, "0")}`;
    }
  } else if(split.length === 1) {
    if(input.length <= 2) {
      const hour = parseInt(input);

      if(hour >= 0 && hour <= 23) {
        return `${hour}:00`;
      }
    } else if(input.length <= 4) {
      const hour = parseInt(input.slice(0, 2));
      const minute = parseInt(input.slice(2));

      if(hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
        return `${hour}:${padLeft(minute, 2, "0")}`;
      }
    }
  }

  return "";
}

function isTimeValid(time: string): boolean {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}
</script>