<template>
  <DynamicDialog />
  <ConfirmDialog />
  <ConfirmPopup />
  <router-view :key="companyStore.selectedCompany?.id"></router-view>
</template>
<script lang="ts" setup>
import { RouterView } from 'vue-router';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';

import { useCompanyStore } from '../stores/company.store';

const companyStore = useCompanyStore();
</script>