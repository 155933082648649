<template>
  <LayoutComponent v-if="companyStore.selectedCompany">
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <h1>{{ companyStore.selectedCompany.name }}</h1>
        <TabMenu :model="items" class="mb-3">
          <template #item="{ item, props }">
            <router-link v-slot="{ href, navigate }" :to="item.route" custom>
              <a :href="href" v-bind="props.action" @click="navigate" :class="item.class">
                <span v-bind="props.icon" />
                <span v-bind="props.label">{{ item.label }}</span>
              </a>
            </router-link>
          </template>
        </TabMenu>
        <router-view />
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";
import TabMenu from 'primevue/tabmenu';

// Components
import LayoutComponent from './layout.component.vue';

// Stores
import { useCompanyStore } from '../stores/company.store';
import { MenuItem } from "primevue/menuitem";

const companyStore = useCompanyStore();

const items = ref<MenuItem[]>([
    { label: 'Settings', route: '/company/settings' },
    { label: 'Clients', route: '/company/clients' },
    { label: 'Tasks', route: '/company/tasks' },
    { label: 'Persons', route: '/company/persons' },
    { label: 'Danger', icon: 'pi pi-exclamation-triangle', class: 'text-red-600', route: '/company/danger' },
]);
</script>