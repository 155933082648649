<template>
  <div class="flex justify-content-end align-items-center mb-3">
    <Button label="Create" @click="createClient" />
  </div>
  <DataTable :value="clientStore.clients">
    <Column field="name" header="Name" />
    <Column field="numberOfProjects" header="Projects" />
    <Column body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
      <template #body="{ data }">
        <ellipsis-resource-menu-component :items="[
          { label: 'Edit', icon: 'pi pi-pencil', action: () => editClient(data) },
          { label: 'Delete', icon: 'pi pi-trash', action: () => removeClient(data) }
        ]" />
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts" setup>
// PrimeVue
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';

// Stores
import { useClientStore } from "../stores/client.store";

// Components
import ClientCreateComponent from "./client-create.component.vue"
import ClientEditComponent from "./client-edit.component.vue"
import ClientRemoveComponent from "./client-remove.component.vue"
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';

// DTO's
import { Client } from '../dto/client';

const dialog = useDialog();
const clientStore = useClientStore();

function createClient(): void {
  dialog.open(ClientCreateComponent, {
    props: {
      header: "Create Client",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

function editClient(client: Client): void {
  dialog.open(ClientEditComponent, {
    data: {
      client
    },
    props: {
      header: "Edit Client",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

function removeClient(client: Client) {
  dialog.open(ClientRemoveComponent, {
    data: {
      client
    },
    props: {
      header: "Delete Client",
      contentStyle: {
        padding: "1rem 2rem",
      },
      style: {
        width: "25vw",
        padding: "0px",
        margin: "0px"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}
</script>
