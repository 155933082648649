
<template>
  <Button type="button" icon="pi pi-ellipsis-v" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" :disabled="disabled" />
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
    <template #item="{ item, props }">
      <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
        <a :href="href" v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
      <a v-else v-bind="props.action" @click="event => item.action(event)">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </template>
  </Menu>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";

// PrimeVue
import { MenuItem } from "primevue/menuitem";
import Menu from 'primevue/menu';
import Button from 'primevue/button';

withDefaults(defineProps<{
    items: MenuItem[],
    disabled?: boolean,
}>(), {
    disabled: false
});

const menu = ref();

function toggle(event: MouseEvent): void {
    menu.value.toggle(event);
};

</script>