<template>
  <template v-if="person">
    {{ person.name }}
  </template>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { usePersonStore } from '../stores/person.store';
import { Person } from '../dto/person';
import { User } from '../dto/user';

const $props = defineProps<{
  userId: string,
}>();

const personStore = usePersonStore();

const person = computed<Person<User> | undefined>(() => personStore.getPersonFromUser($props.userId));
</script>