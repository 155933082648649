<template>
  <form @submit.prevent="submit(person.id)">
    <PersonFormComponent v-model="personForm" :linkedUser="person.user?.id" @isValid="value => isValid = value" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" label="Update" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import PersonFormComponent, { PersonForm } from './person-form.component.vue';

// Stores
import { usePersonStore } from '../stores/person.store';

// DTO's
import { Person } from '../dto/person';
import { User } from '../dto/user';

const dialogRef: any = inject('dialogRef');

const person: Person<User> = dialogRef.value.data.person;
const personStore = usePersonStore();

const saving = ref(false);
const isValid = ref(false);

const personForm = ref<PersonForm>({
  name: "",
  defaultRate: undefined
});

onMounted(() => {
  if (person) {
    personForm.value.name = person.name;
    personForm.value.defaultRate = person.defaultRate;

    if(person.user?.id) {
      personForm.value.user = person.user.id;
    }
  }
})

async function submit(personId: string) {
  saving.value = true;
  await personStore.update(personId, {
    name: personForm.value.name.trim(),
    ...(personForm.value.defaultRate ? { defaultRate: personForm.value.defaultRate } : {}),
    ...(personForm.value.user ? { user: personForm.value.user } : {}),
  });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>