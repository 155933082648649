<template>
  <div class="flex justify-content-end align-items-center mb-3">
    <Button label="Create" @click="createPerson" />
  </div>
  <DataTable :value="personStore.persons">
    <Column field="name" header="Name" />
    <Column field="defaultRate" header="Rate">
      <template #body="{ data }">
        <template v-if="data.defaultRate">
          {{ swedishKronerFormat(data.defaultRate) }}/h
        </template>
      </template>
    </Column>
    <Column field="user.email" header="User">
      <template #body="{ data }">
        <template v-if="data.user">
          {{ data.user.email }}
          <template v-if="data.user.id === authStore.me?.id">
            <i class="text-400">(you)</i>
          </template>
        </template>
      </template>
    </Column>
    <Column field="hoursToday" header="Hours worked today">
      <template #body="{ data }">
          {{ data.hoursToday }}h
        </template>
    </Column>
    <Column body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
      <template #body="{ data }">
        <ellipsis-resource-menu-component :items="[
          { label: 'Edit', icon: 'pi pi-pencil', action: () => editPerson(data) },
          { label: 'Delete', icon: 'pi pi-trash', action: () => removePerson(data) }
        ]" />
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts" setup>
// PrimeVue
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';

// Stores
import { usePersonStore } from "../stores/person.store";

// Components
import PersonCreateComponent from "./person-create.component.vue"
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';
import PersonEditComponent from "./person-edit.component.vue"
import PersonRemoveComponent from "./person-remove.component.vue"

// DTO's
import { Person } from '../dto/person';

// Utils
import { swedishKronerFormat } from "../utils";
import { useAuthStore } from '../stores/auth.store';

const dialog = useDialog();
const personStore = usePersonStore();
const authStore = useAuthStore();

function createPerson(): void {
  dialog.open(PersonCreateComponent, {
    props: {
      header: "Create Person",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}
function editPerson(person: Person): void {
  dialog.open(PersonEditComponent, {
    data: {
      person
    },
    props: {
      header: "Edit Person",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

function removePerson(person: Person) {
  dialog.open(PersonRemoveComponent, {
    data: {
      person
    },
    props: {
      header: "Delete Person",
      contentStyle: {
        padding: "1rem 2rem",
      },
      style: {
        width: "25vw",
        padding: "0px",
        margin: "0px"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}

</script>
