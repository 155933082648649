<template>
  <form @submit.prevent="submit(track.id, trackForm)" v-if="!loading">
    <track-form-component v-model="trackForm" @isValid="validate" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" icon="pi pi-plus" label="Save" icon-pos="right" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import TrackFormComponent, { TrackForm } from './track-form.component.vue';

// Stores
import { useTrackStore } from '../stores/track.store';
import { usePersonStore } from '../stores/person.store';
import { Track } from '../dto/track';
import { onMounted } from 'vue';
import { padLeft } from '../utils';
import { useAuthStore } from '../stores/auth.store';

const trackStore = useTrackStore();
const personStore = usePersonStore();
const authStore = useAuthStore();

const dialogRef: any = inject('dialogRef');
const track: Track = dialogRef.value.data.track;

const trackForm = ref<TrackForm>({
  project: "",
  task: "",
  person: "",
  date: new Date(),
  description: "",
  start: "",
  end: ""
});

const isValid = ref(false);
const saving = ref(false);
const loading = ref(true);

function validate(formIsValid: boolean) {
  if(!formIsValid) {
    isValid.value = false;
    return;
  }
  
  if(trackForm.value.start === "") {
    isValid.value = false;
    return;
  }
  
  if(track.endDate !== undefined && trackForm.value.end === "") {
    isValid.value = false;
    return;
  }
  
  isValid.value = true;
}

const trackDate = new Date(track.startDate);


onMounted(() => {
  loading.value = true;
  if (track) {
    trackForm.value.project = track.project.id;
    trackForm.value.task = track.task.id;
    trackForm.value.person = track.person.id;
    trackForm.value.description = track.description;
    trackForm.value.start = dateToTimeString(new Date(track.startDate));
    trackForm.value.end = track.endDate ? dateToTimeString(new Date(track.endDate)) : "";
  }
  loading.value = false;
})

async function submit(trackId, track: TrackForm) {
  const startDate = track.start !== "" ? dateAndTimeToDate(trackDate, track.start) : new Date();
  const endDate = track.end !== "" ? dateAndTimeToDate(trackDate, track.end) : undefined;

  if(endDate && endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  
  await trackStore.update(trackId, {
    project: track.project,
    task: track.task,
    person: authStore.isAdmin ? track.person : personStore.me!.id,
    startDate: startDate.toISOString(),
    ...(endDate !== undefined ? { endDate: endDate.toISOString() } : {}),
    description: track.description
  });

  close();
}

function cancel() {
  close();
}
function close() {
  dialogRef.value.close();
}

function dateAndTimeToDate(date: Date, time: string): Date {
  const [hours, minutes] = time.split(":").map(Number);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
}

function dateToTimeString(date: Date): string {
  const hours = date.getHours().toString();
  const minutes = padLeft(date.getMinutes(), 2, "0)");

  return `${hours}:${minutes}`;
}

</script>