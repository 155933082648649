<template v-if="ready">
  <report-group-list-component :tracks="tracks" :group-by="dimensions[0]" :group="getGroup(dimensions[0])">
    <template v-if="dimensions[1]" #default="firstGroup">
      <report-group-list-component :tracks="filterTrackFor(tracks, { [dimensions[0] + 'Id']: firstGroup.groupId })" :group-by="dimensions[1]" :group="getGroup(dimensions[1])" :is-root="false">
        <template v-if="dimensions[2]" #default="secondGroup">
          <report-group-list-component :tracks="filterTrackFor(tracks, { [dimensions[0] + 'Id']: firstGroup.groupId, [dimensions[1] + 'Id']: secondGroup.groupId })" :group-by="dimensions[2]" :group="getGroup(dimensions[2])" :is-root="false">
            <template v-if="dimensions[3]" #default="thirdGroup">
            <report-group-list-component :tracks="filterTrackFor(tracks, { [dimensions[0] + 'Id']: firstGroup.groupId, [dimensions[1] + 'Id']: secondGroup.groupId, [dimensions[2] + 'Id']: thirdGroup.groupId })" :group-by="dimensions[3]" :group="getGroup(dimensions[3])" :is-root="false" />
          </template>
          </report-group-list-component>
        </template>
      </report-group-list-component>
    </template>
  </report-group-list-component>
</template>
<script lang="ts" setup>
// Libs
import { defineProps, onMounted, ref } from 'vue';

import ReportGroupListComponent from './report-group-list.component.vue';

// DTO's
import { Track } from '../dto/track';
import { Project } from '../dto/project';
import { Task } from '../dto/task';
import { Person } from '../dto/person';
import { Client } from '../dto/client';

const $props = defineProps<{
  dimensions: ("client" | "project" | "task" | "person")[];
  tasks: Task[];
  projects: Project[];
  clients: Client[];
  persons: Person[];
  tracks: Track[];
}>();

const ready = ref(false);

onMounted(() => {
  if($props.dimensions.length === 0) {
    throw new Error('At least one dimension must be provided');
  } else if ($props.dimensions.length > 4) {
    throw new Error('Maximum of three dimensions can be provided');
  }

  if (!isEachElementOfArrayUnique($props.dimensions)) {
    throw new Error('Each dimension must be unique');
  }

  ready.value = true;
});

function getGroup(dimension: string): { id: string, name: string}[] {
  if(dimension === 'client') {
    return $props.clients.map((client) => ({ id: client.id, name: client.name }));
  } else if(dimension === 'project') {
    return $props.projects.map((project) => ({ id: project.id, name: project.name }));
  } else if(dimension === 'task') {
    return $props.tasks.map((task) => ({ id: task.id, name: task.name }));
  } else {
    return $props.persons.map((person) => ({ id: person.id, name: person.name }));
  }

}

function filterTrackFor(
  tracks: Track[],
  { clientId, projectId, taskId, personId }: Record<string, string> = {}
): Track[] {
  return tracks.filter((track) => {
    if(clientId && track.project.client.id !== clientId) {
      return false;
    }
    if (projectId && track.project.id !== projectId) {
      return false;
    }

    if (taskId && track.task.id !== taskId) {
      return false;
    }
    
    if (personId && track.person.id !== personId) {
      return false;
    }

    return true;
  });
}

function isEachElementOfArrayUnique(array: string[]): boolean {
  return new Set(array).size === array.length;
}
</script>