<template>
  <form @submit.prevent="submit(taskForm)">
    <TaskFormComponent v-model="taskForm" @isValid="value => isValid = value" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" icon="pi pi-plus" label="Create" icon-pos="right" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Stores
import { useTaskStore } from '../stores/task.store';

// Components
import TaskFormComponent, { TaskForm } from './task-form.component.vue';

const dialogRef: any = inject('dialogRef');

const taskStore = useTaskStore();

const saving = ref(false);
const isValid = ref(false);

const taskForm = ref<TaskForm>({
  name: "",
  defaultRate: undefined
});

async function submit(task: TaskForm) {
  saving.value = true;
  await taskStore.create({
    name: taskForm.value.name.trim(),
    ...(taskForm.value.defaultRate ? { defaultRate: taskForm.value.defaultRate } : {})
  });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>