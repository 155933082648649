<template>
  <layout-component>
    <!--<div class="grid grid-nogutter">
      <div class="col-offset-2 col-10">
        <plan-days-component :date="date" />
      </div>
      <div class="col-12 m-0">
        <div class="grid grid-nogutter">
          <div class="col-2">
            <plan-persons-component :persons="personStore.persons" />
          </div>
          <div class="col-10">
            <plan-schedule-component :date="date" :rows="personStore.persons.length" v-model="plans" />
          </div>
        </div>
      </div>
    </div>-->
    <schedule-component v-model="entites" :labels="[1,2,3,4,5,6,7,8,9,10]">
      <template #entity="{ unit, index }">
        {{ unit.entity.label }}-{{ unit.start }}/{{ unit.end }}
      </template>
    </schedule-component>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";

// Components
import LayoutComponent from "./layout.component.vue";
import ScheduleComponent, { Entity } from "./schedule.component.vue";

// Stores
import { usePersonStore } from "../stores/person.store";

const entites = ref<Entity[]>([
  { label: 'John', units: [
    // { start: 0, end: 3 },
    // { start: 3, end: 6 }
  ]}, { label: 'Jane', units: [
    { start: 0, end: 8 },
    { start: 3, end: 4, },
    // { start: 0, end: 2, },
    // { start: 3, end: 6, },
    // { start: 0, end: 2, }
  ]}
]);

export type Plan = {
  fromDate: Date;
  toDate: Date;
  project: string;
  person: string;
}

const personStore = usePersonStore();

const date = ref(new Date());
const plans = ref<Plan[]>([{
  fromDate: new Date("2024-05-01"),
  toDate: new Date("2024-05-05"),
  project: "1",
  person: "1"
}]);
</script>