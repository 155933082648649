<template>
  <layout-component>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <Panel>
          <template #header>Register</template>
          <Message v-if="success" severity="success" :closable="false">
            Registration was successfull! Please check your email for further instructions.
          </Message>
          <form v-else @submit.prevent="register">
            <div class="field">
              <form-input-component label="Email" input-id="email" :warning="invalid === 'email_exists'" required>
                <InputText name="email" ref="emailInput" type="email" :disabled="loading" :class="['w-full', { 'p-invalid': invalid === 'email_exists' }]" id="email" v-model="form.email" />
                <template #warning>
                  User with email already exists.
                  <router-link :to="`/forgot-password?email=${form.email}`">
                    Click here if you forgot your password
                  </router-link>
                </template>
              </form-input-component>
            </div>
            <div class="field">
              <form-input-component label="Name" input-id="name">
                <InputText name="name" :disabled="loading" class="w-full" id="name" v-model="form.name" placeholder="Not required" />
              </form-input-component>
            </div>
            <div class="field">
              <form-input-component label="Password" input-id="password" required>
                <InputText name="password" ref="passwordInput" :disabled="loading" type="password" :class="['w-full', { 'p-invalid': repeatPasswordInvalid }]" id="password" v-model="form.password" />
              </form-input-component>
            </div>
            <div class="field">
              <form-input-component label="Repeat" input-id="repeatPassword" required>
                <InputText name="password-repeat" :disabled="loading" type="password" :class="['w-full', { 'p-invalid': repeatPasswordInvalid }]" id="repeatPassword" v-model="form.repeatPassword" />
              </form-input-component>
            </div>
            <Message v-if="invalid === true" severity="error" v-on:close="invalid = false">
              Something went wrong, please try again
            </Message>
            <div class="flex gap-3">
              <Button type="submit" :disabled="loading || !isValid">
                <template v-if="!loading">
                  Register
                </template>
                <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
              </Button>
              <div class="flex-grow-1 text-right flex gap-1 justify-content-end align-items-end">
                <router-link to="/login">
                  Login
                </router-link>
                |
                <router-link to="/forgot-password">
                  Forgot password
                </router-link>
              </div>
            </div>
          </form>
        </Panel>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

import LayoutComponent from './layout.component.vue';
import FormInputComponent from './form-input.component.vue';

import { useAuthStore } from '../stores/auth.store';
import { getRecaptcha, queryStringFromRoute } from '../utils';
import { onMounted } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';

const route = useRoute();
const reCaptcha = useReCaptcha();

const loading = ref(false);
const invalid = ref<boolean | 'email_exists'>(false);
const success = ref(false);

const emailInput = ref<HTMLInputElement | null>(null);
const passwordInput = ref<HTMLInputElement | null>(null);

const form = ref({
  email: "",
  name: "",
  password: "",
  repeatPassword: ""
})

const authStore = useAuthStore();

const isValid = computed(() => {
  return form.value.email.length > 0 && form.value.password.length > 0 && form.value.password === form.value.repeatPassword;
});
const repeatPasswordInvalid = computed(() => {
  return form.value.password !== form.value.repeatPassword;
});

const emailQuery = queryStringFromRoute(route, 'email');

onMounted(() => {
  (emailInput.value as any)?.$el.focus();
  if(emailQuery) {
    form.value.email = emailQuery;
    (passwordInput.value as any)?.$el.focus();
  }
});

async function register() {
  loading.value = true;
  
  // Get captcha code
  const captchaCode = await getRecaptcha(reCaptcha!);
  try {
    await authStore.register(captchaCode, {
      email: form.value.email,
      ...(form.value.name.trim() !== "" ? { name: form.value.name } : {}),
      password: form.value.password
    });
    success.value = true;
  } catch (error) {
    if(error.message === 'User already exists') {
      invalid.value = 'email_exists';
    } else {
      invalid.value = true;
    }
  } finally {
    loading.value = false;
  }
}

</script>