<template>
  <div class="grid">
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <FormInputComponent label="Name" input-id="name" help="Name of person" required>
        <InputText class="w-full" id="name" v-model="personForm.name" :disabled="saving" />
      </FormInputComponent>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <FormInputComponent label="Rate" input-id="rate" help="Default hourly rate">
        <InputNumber class="w-full" id="rate" v-model="personForm.defaultRate" locale="sv-SE" mode="currency" currency="SEK" :disabled="saving" />
      </FormInputComponent>
    </div>
    <div class="col-12 xl:col-6 xl:col-offset-3">
      <FormInputComponent label="User" input-id="user" help="Link to user">
        <Dropdown v-model="personForm.user" :options="users" optionLabel="label" optionValue="value" show-clear :disabled="saving" />
      </FormInputComponent>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { ref, computed, onMounted, watch } from 'vue';

// PrimeVue
import InputText from 'primevue/inputtext';
import InputNumber from "primevue/inputnumber";
import Dropdown from 'primevue/dropdown';

// Components
import FormInputComponent from './form-input.component.vue';

// DTO's
import { PersonCreate } from '../dto/person';
import { useCompanyStore } from '../stores/company.store';
import { usePersonStore } from '../stores/person.store';

export type PersonForm = PersonCreate;

const $emits = defineEmits(["isValid"]);

const companyStore = useCompanyStore();
const personStore = usePersonStore();

const saving = ref<boolean>(false);

const users = computed(() => companyStore.selectedCompany?.users.reduce((previousValue, user) => [
  ...previousValue,
  ...(!personStore.persons.find(person => person.user?.id === user.user.id) || user.user.id === linkedToUser.value ? [{ label: user.user.email, value: user.user.id }] : []),
], [] as { label: string, value: string }[]));

const personForm = defineModel<PersonCreate>({ required: true });
const linkedToUser = defineModel<string>("linkedUser");

const isValid = computed<boolean>(() => personForm.value.name.trim() !== "");

watch(personForm, () => {
  $emits("isValid", isValid.value);
}, { deep: true, immediate: true });

onMounted(() => {
  $emits("isValid", isValid.value);
});
</script>