import BaseStyle from 'primevue/base/style';

var classes = {
  root: function root(_ref) {
    var props = _ref.props;
    return ['p-panel p-component', {
      'p-panel-toggleable': props.toggleable
    }];
  },
  header: 'p-panel-header',
  title: 'p-panel-title',
  icons: 'p-panel-icons',
  toggler: 'p-panel-header-icon p-panel-toggler p-link',
  toggleablecontent: 'p-toggleable-content',
  content: 'p-panel-content',
  footer: 'p-panel-footer'
};
var PanelStyle = BaseStyle.extend({
  name: 'panel',
  classes: classes
});

export { PanelStyle as default };
