<template>
  <Fieldset class="mb-5" legend="Update Company" v-if="authStore.isAdmin">
    <form @submit.prevent="submit(companyForm)">
      <company-form-component v-model="companyForm" @isValid="validate" :saving="saving" />
      <div class="flex justify-content-end">
        <Button type="submit" :disabled="!isValid" :loading="saving" label="Save Company" />
      </div>
    </form>
  </Fieldset>
  <Fieldset class="mb-5" legend="Users">
    <DataTable data-test="company-users-list" :value="companyStore.selectedCompany.users || []" class="mb-5" :row-class="data => data.user.id === authStore.me?.id ? ['bg-green-100'] : []">
      <Column field="user.email" header="Email"></Column>
      <Column field="user.name" header="Name">
        <template #body="{ data }">
          {{ data.user.name }}
          <i v-if="data.user.id === authStore.me?.id" class="text-400">(you)</i>
        </template>
      </Column>
      <Column field="role" header="Role"></Column>
      <Column field="active" header="Active"></Column>
      <Column field="role" header="Person">
        <template #body="{ data }">
          <company-user-person-component :userId="data.user.id" />
        </template>
      </Column>
      <Column v-if="authStore.isAdmin" body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
        <template #body="{ data }">
          <ellipsis-resource-menu-component :disabled="data.role === 'owner' || (data.role === 'admin' && authStore.isAdmin && !authStore.isOwner) || data.user.id === authStore.me?.id" :items="[
            ...(authStore.isOwner && data.role === 'admin' ? [{ label: 'Transfer Ownership', icon: 'fa-solid fa-crown', action: () => TransferOwnershipToUser(data.user) }] : []),
            ...(authStore.isOwner && data.role === 'admin' ? [{ label: 'Demote to user', icon: 'fa-solid fa-user', action: () => demoteUser(data.user) }] : []),
            ...(authStore.isOwner && data.role === 'user' ? [{ label: 'Promote to Admin', icon: 'fa-solid fa-user-tie', action: () => PromoteUser(data.user) }] : []),
            ...((authStore.isOwner && (data.role === 'user' || data.role === 'admin')) || (authStore.isAdmin && (data.role === 'user')) ? [{ label: 'Remove', icon: 'pi pi-trash', action: () => removeUser(data.user) }] : []),
            ...(authStore.isAdmin && !personStore.getPersonFromUser(data.user.id) ? [{ label: 'Create person for user', action: () => createPersonForUser(data.user) }]: [])
          ]" />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
  <Fieldset class="mb-5" legend="Invite" v-if="authStore.isAdmin">
    <company-invite-component :company="companyStore.selectedCompany" />
  </Fieldset>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useDialog } from 'primevue/usedialog';

// Components
import LayoutComponent from './layout.component.vue';
import CompanyFormComponent, { CompanyForm } from './company-form.component.vue';
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';
import CompanyInviteComponent from './company-invite.component.vue';
import CompanyUserPersonComponent from './company-user-person.component.vue';

// Stores
import { useCompanyStore } from '../stores/company.store';
import { onMounted } from 'vue';
import { User } from '../dto/user';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { usePersonStore } from '../stores/person.store';
import { useAuthStore } from '../stores/auth.store';
import { computed } from 'vue';
import { openDialog } from '../dialog';
import CompanyUserDemoteComponent from './company-user-demote.component.vue';
import CompanyUserPromoteComponent from './company-user-promote.component.vue';
import CompanyUserRemoveComponent from './company-user-remove.component.vue';
import CompanyUserTransferOwnershipComponent from './company-user-transfer-ownership.component.vue';

import { useUserStore } from '../stores/user.store';

const dialog = useDialog();

const companyStore = useCompanyStore();
const personStore = usePersonStore();
const authStore = useAuthStore();
const userStore = useUserStore();

const saving = ref(false);
const isValid = ref(false);

const companyForm = ref<CompanyForm>({
  name: "",
});

const companyId = computed(() => {
  const companyId = companyStore.selectedCompany?.id;
  if(companyId === undefined) {
    throw new Error("Company not found");
  }
  return companyId;
});

onMounted(() => {
  companyForm.value.name = companyStore.selectedCompany?.name || "";
});

function validate(valid: boolean) {
  if(valid && companyForm.value.name !== companyStore.selectedCompany?.name) {
    isValid.value = true;
  } else {
    isValid.value = false;
  }
}

async function submit(company: CompanyForm) {
  if(companyStore.selectedCompany === undefined) {
    return;
  }

  saving.value = true;
  await companyStore.update(companyStore.selectedCompany.id, {
    name: company.name
  });

  companyStore.reloadCompanies();
  saving.value = false;
}

function TransferOwnershipToUser(user: User): void {
  openDialog({
    dialog,
    component: CompanyUserTransferOwnershipComponent,
    header: "Transfer ownership",
    data: { user }
  });
}
function demoteUser(user: User): void {
  openDialog({
    dialog,
    component: CompanyUserDemoteComponent,
    header: "Demote user",
    data: { user }
  });
}
function PromoteUser(user: User): void {
  openDialog({
    dialog,
    component: CompanyUserPromoteComponent,
    header: "Promote user",
    data: { user }
  });
}

function removeUser(user: User): void {
  openDialog({
    dialog,
    component: CompanyUserRemoveComponent,
    header: "Remove user",
    data: { user }
  });
}

async function createPersonForUser(user: User): Promise<void> {
  await personStore.create({
    name: userStore.getUserName(user),
    user: user.id,
  });

  const company = await companyStore.get(companyId.value);
  companyStore.reloadCompanies();
}

</script>