<template>
  <form @submit.prevent="submit(client.id, clientForm)">
    <client-form-component v-model="clientForm" @isValid="value => isValid = value" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving" label="Cancel" />
      <Button type="submit" label="Save" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import ClientFormComponent, { ClientForm } from './client-form.component.vue';

// Stores
import { useClientStore } from '../stores/client.store';

// DTO's
import { Client } from '../dto/client';

const dialogRef: any = inject('dialogRef');

const client: Client = dialogRef.value.data.client;
const clientStore = useClientStore();

const saving = ref(false);
const isValid = ref(false);

const clientForm = ref<ClientForm>({
  name: "",
});

onMounted(() => {
  if (client) {
    clientForm.value.name = client.name;
  }
})

async function submit(clientId: string, client: ClientForm) {
  saving.value = true;
  await clientStore.update(clientId, {
    name: client.name
  });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>