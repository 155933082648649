<template>
  <LayoutComponent>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <div class="flex justify-content-between align-items-center mb-3">
          <h1>Projects</h1>
          <router-link to="/projects/create">
            <Button label="Create Project" />
          </router-link>
        </div>
        <DataTable :value="projectStore.projects" data-test="project-list">
          <Column field="name" header="Name"></Column>
          <Column field="client.name" header="Client"></Column>
          <Column field="description" header="Description"></Column>
          <Column body-class="flex justify-content-end gap-2" header-class="text-right flex justify-content-end" header="&nbsp;">
            <template #body="slotProps">
              <ellipsis-resource-menu-component :items="[
                { label: 'View', icon: 'pi pi-eye', route: `/projects/view/${slotProps.data.code}` },
                { label: 'Edit', icon: 'pi pi-pencil', route: `/projects/edit/${slotProps.data.code}` },
                { label: 'Delete', icon: 'pi pi-trash', action: () => removeProject(slotProps.data.code) }
              ]" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { useDialog } from 'primevue/usedialog';

// PrimeVue
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

// Stores
import { useProjectStore } from '../stores/project.store';

// Components
import LayoutComponent from './layout.component.vue';
import EllipsisResourceMenuComponent from './ellipsis-resource-menu.component.vue';
import ProjectRemoveComponent from './project-remove.component.vue';

const dialog = useDialog();

const projectStore = useProjectStore();

function removeProject(projectCode: string) {
  dialog.open(ProjectRemoveComponent, {
    data: {
      projectCode
    },
    props: {
      header: "Delete Project",
      contentStyle: {
        padding: "1rem 2rem",
      },
      style: {
        width: "25vw",
        padding: "0px",
        margin: "0px"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}
</script>