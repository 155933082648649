<template>
  <template v-if="loading">
    <div class="w-100 flex justify-content-center">
      <i class="pi pi-spin pi-spinner" style="font-size: 2.5rem" />
    </div>
  </template>
  <template v-else>
    <template v-if="clientHasProjects">
      <div>
        <p>This client has projects and cannot be deleted. Instead delete each project for client first then come back here to delete the client</p>
      </div>
    </template>
    <template v-else>
      <confirm-component @submit="submit" @cancel="cancel" v-model:loading="submitLoading">
        Are you sure you want to delete this client?
      </confirm-component>
    </template>
  </template>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useClientStore } from '../stores/client.store';
import { Client } from '../dto/client';

const dialogRef: any = inject('dialogRef');

const clientStore = useClientStore();

const loading = ref(false);
const submitLoading = ref(false);
const clientHasProjects = ref(false);

const client: Client = dialogRef.value.data.client;

onMounted(async () => {
  loading.value = true;
  clientHasProjects.value = await clientStore.clientHasProjects(client.id);
  loading.value = false;
});

async function submit() {
  submitLoading.value = true;
  await clientStore.remove(client.id);
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>