<template>
  <layout-component>
    <Panel class="w-6 mx-auto">
      <template #header>
        <template v-if="isPasswordReset">
          Reset password
        </template>
        <template v-else>
          Activate account
        </template>
      </template>
      <Message v-if="success" :closable="false">
        <template v-if="isInivtation">
          <template v-if="isNewUser">
            Invitation accepted and account activated successfully,
          </template>
          <template v-else>
            Invitation accepted successfully,
          </template>
        </template>
        <template v-else>
          <template v-if="isPasswordReset">
            Password reset successfully,
          </template>
          <template v-else>
            Account activated successfully,
          </template>
        </template>
        <router-link :to="`/login?email=${email}`">
          you can now login
        </router-link>
      </Message>
      <form v-else @submit.prevent="submit">
        <template v-if="isInivtation">
          <p v-if="isNewUser">
            Welcome to <b>{{ company }}</b>! Please create a password and optional name to activate your account.
          </p>
          <p class="text-center" v-else>
            You have been invited to join <b>{{ company }}</b>! Press the button to continue.
          </p>
        </template>
        <template v-else>
          <p v-if="isNewUser">
            Congratulation, you're one step away from activate your account! Just create a password and optional name, and press <i><b>Activate account</b></i> to continue.
          </p>
          <p v-else-if="isPasswordReset">
            Please provide a new password to reset your account.
          </p>
          <p v-else class="text-center">
            Congratulation, you're one step away from activate your account! Press the button to continue
          </p>
        </template>
        <template v-if="isNewUser">
          <div class="field">
            <form-input-component label="Name" input-id="name">
              <InputText :disabled="loading" class="w-full" id="name" v-model="form.name" placeholder="Not required" />
            </form-input-component>
          </div>
        </template>
        <template v-if="isNewUser || isPasswordReset">
          <div class="field">
            <form-input-component label="Password" input-id="password" required>
              <InputText :disabled="loading" type="password" :class="['w-full', { 'p-invalid': repeatPasswordInvalid }]" id="password" v-model="form.password" />
            </form-input-component>
          </div>
          <div class="field">
            <form-input-component label="Repeat" input-id="repeatPassword" required>
              <InputText :disabled="loading" type="password" :class="['w-full', { 'p-invalid': repeatPasswordInvalid }]" id="repeatPassword" v-model="form.repeatPassword" />
            </form-input-component>
          </div>
        </template>
        <Message v-if="invalid" severity="error" class="w-full" :closable="false">
          <template v-if="isPasswordReset">
            Password reset failed, please try again
          </template>
          <template v-else>
            Activation failed, please try again
          </template>
        </Message>
        <div :class="['flex gap-3', { 'justify-content-center': !newUser }]">
          <Button type="submit" :loading="loading" :disabled="!isValid || loading">
            <i v-if="loading" class="pi pi-spin pi-spinner mr-2"></i>
            <template v-if="isInivtation">
              Accept invitation
            </template>
            <template v-if="isPasswordReset">
              Reset password
            </template>
            <template v-else>
              Activate account
            </template>
          </Button>
        </div>
      </form>
    </Panel>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref } from 'vue';
import jwt from "jsonwebtoken";

// PrimeVue
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';

// Components
import LayoutComponent from './layout.component.vue';
import FormInputComponent from './form-input.component.vue';

// Stores
import { useAuthStore } from '../stores/auth.store';
import { getRecaptcha, queryStringFromRoute } from '../utils';
import { useRoute } from 'vue-router';
import { useReCaptcha } from 'vue-recaptcha-v3';

const route = useRoute();

const authStore = useAuthStore();
const reCaptcha = useReCaptcha();

const code = queryStringFromRoute(route, 'code');
const decodedCode = computed(() => {
  if(!code) { return; }

  const decoded = jwt.decode(code);
  if(typeof decoded === "object" && decoded !== null) {
    return decoded;
  }
});
const email = computed(() => decodedCode.value?.email);
const userId = computed(() => decodedCode.value?.userId);
const company = computed(() => decodedCode.value?.company);
const requireNewPassword = computed(() => decodedCode.value?.requireNewPassword);
const newUser = computed(() => decodedCode.value?.newUser);

const isInivtation = computed(() => company.value);
const isPasswordReset = computed(() => requireNewPassword.value);
const isNewUser = computed(() => newUser.value);

const loading = ref(false);
const invalid = ref(false);
const success = ref(false);

const form = ref({
  name: '',
  password: '',
  repeatPassword: ''
});

const repeatPasswordInvalid = computed(() => {
  return form.value.password !== form.value.repeatPassword;
});
const isValid = computed(() => {
  return !newUser.value || (form.value.password.length > 0 && form.value.password === form.value.repeatPassword);
});

async function submit() {
  loading.value = true;
  const captchaCode = await getRecaptcha(reCaptcha!);
  if(code === undefined || userId.value === undefined) {
    invalid.value = true;
  } else {
    try {
      await authStore.activateAccount({
        captchaCode,
        code,
        ...newUser ? {
          password: form.value.password,
          ...(form.value.name.trim() !== "" ? { name: form.value.name } : {})
        } : {}
      });
      success.value = true;
    } catch(error) {
      invalid.value = true;
    }
  }

  loading.value = false;
}
</script>
<style lang="scss" scoped>

</style>