<template>
  <DataTable :value="tracks">
    <Column field="project.name" header="Project" />
    <Column field="task.name" header="Task" />
    <Column field="person.name" header="Person" />
    <Column header="Hours">
      <template #body="{ data }">
        {{ round(hoursBetweenDates(data.startDate, data.endDate), 2) }}h
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts" setup>
// Libs
import { defineProps } from 'vue';

// PrimeVue
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

// DTO's
import { Track } from '../dto/track';
import { hoursBetweenDates, round } from '../utils';

const $props = defineProps<{
  tracks: Track[],
}>();
</script>