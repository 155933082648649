<template>
  <template v-if="loading">
    <div class="w-100 flex justify-content-center">
      <i class="pi pi-spin pi-spinner" style="font-size: 2.5rem" />
    </div>
  </template>
  <template v-else>
    <template v-if="taskHasProjects">
      <div>
        <p>This task has projects and cannot be deleted. Instead delete each project for task first then come back here to delete the task</p>
      </div>
    </template>
    <template v-else>
      <confirm-component @submit="submit" @cancel="cancel" v-model:loading="submitLoading">
        Are you sure you want to delete this task?
      </confirm-component>
    </template>
  </template>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useTaskStore } from '../stores/task.store';
import { Task } from '../dto/task';

const dialogRef: any = inject('dialogRef');

const taskStore = useTaskStore();

const loading = ref(false);
const submitLoading = ref(false);
const taskHasProjects = ref(false);

const task: Task = dialogRef.value.data.task;

onMounted(async () => {
  loading.value = true;
  taskHasProjects.value = await taskStore.taskHasProjects(task.id);
  loading.value = false;
});

async function submit() {
  submitLoading.value = true;
  await taskStore.remove(task.id);
  dialogRef.value.close();
}

function cancel() {
  dialogRef.value.close();
}
</script>