<template>
    <Menubar :model="items">
      <template #start>
        <div class="mr-2">
          <i class="fa-solid fa-user-tie fa-2xl"></i>
          Time
        </div>
      </template>
      <template #item="{ item, props }">
        <router-link v-slot="{ href, navigate, isActive }" :to="item.route" custom>
          <a :href="href" :class="{ 'router-link-active': isActive }" v-bind="props.action" @click="navigate">
            {{ item.label }}
          </a>
        </router-link>
      </template>
      <template #end>
        <div class="flex gap-3">
          <template v-if="companyStore.selectedCompany">
            <Button class="block md:hidden" icon="pi pi-building" severity="info" v-tooltip.top="'Switch Company'" @click="companyStore.clearSelectedCompany" />
            <SplitButton class="hidden md:flex" :buttonProps="{
              class: 'p-0'
            }" :model="companyList" outlined severity="contrast" :label="companyStore.selectedCompany.name" >
              <template #default>
                <router-link to="/company" class="p-menuitem-link" style="padding: .75rem 1.25rem; color: inherit; text-decoration: none;" active-class="none">
                  {{ companyStore.selectedCompany.name }}
                </router-link>
              </template>
              <template #item="{ item }">
                <template v-if="item.command">
                  <a class="p-menuitem-link" tabindex="-1" aria-hidden="true" data-pc-section="action">
                    <span id="pv_id_10_overlay_0_label" data-pc-section="label">{{ item.label }}</span>
                  </a>
                </template>
                <template v-else>
                  <router-link :to="item.route" v-slot="{ href, navigate }" custom>
                    <a class="p-menuitem-link" :href="href" @click="navigate" aria-hidden="true" data-pc-section="action">
                      <span v-if="item.icon" :class="['p-menuitem-icon', item.icon]" data-pc-section="icon"></span>
                      <span data-pc-section="label">{{ item.label }}</span>
                    </a>
                  </router-link>
                </template>
              </template>
            </SplitButton>
          </template>
          <template v-if="!authStore.isAuth">
            <router-link to="/login" active-class="none">
              <Button label="Login" link />
            </router-link>
            <router-link  to="/register">
              <Button label="Register" />
            </router-link>
          </template>
          <template v-else>
            <router-link to="/settings" active-class="none">
              <Button icon="pi pi-cog" link />
            </router-link>
            <router-link class="p-button no-underline" to="/logout">
              Logout
            </router-link>
          </template>
        </div>
      </template>
    </Menubar>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref, onMounted } from 'vue';

// PrimeVue
import Menubar from 'primevue/menubar';
import { MenuItem } from 'primevue/menuitem';
import SplitButton from "primevue/splitbutton";
import Button from "primevue/button";

// Stores
import { useAuthStore } from "../stores/auth.store";
import { useCompanyStore } from "../stores/company.store";

const authStore = useAuthStore();
const companyStore = useCompanyStore();

const items = computed<MenuItem[]>(() => [
  ...(authStore.isAuth && companyStore.selectedCompany ? [
  {
    label: "Track",
    route: "/track"
  },
  {
    label: "Projects",
    route: "/projects"
  },
  {
    label: "Report",
    route: "/report"
  },
  {
    label: "Plan",
    route: "/plan"
  },
] : []),
]);


const companyList = computed<MenuItem[]>(() => [...companyStore.companies.map(company => ({
  label: company.name,
  command: () => {
    companyStore.setSelectedCompany(company.id)
  }
})), { label: "new company", route: "/company/create", icon: "pi pi-plus", class: "bg-primary-100" }]);

</script>
<style scoped>
  .router-link-active {
    background-color: #e5e7eb;
    border-radius: 6px;
  }
</style>