<template>
  <main class="layout surface-ground">
    <header class="layout__header">
      <menu-component></menu-component>
    </header>
    <section class="layout__content surface-ground">
      <slot />
    </section>
    <footer></footer>
  </main>
</template>
<script lang="ts" setup>
import MenuComponent from './menu.component.vue';

</script>
<style lang="scss" scoped>
  .layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    &__header {
      margin-bottom: 2rem;
    }
    &__content {
      padding: 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
</style>
