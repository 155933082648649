<template>
  <LayoutComponent>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <h1>Create Project</h1>
        <form @submit.prevent="submit(projectForm)">
          <project-form-component v-model="projectForm" @isValid="value => isValid = value" />
          <div class="flex justify-content-center">
            <Button type="submit" :disabled="!isValid">
              Create Project
            </Button>
          </div>
        </form>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { ref } from 'vue';
import { useRouter } from 'vue-router';

// PrimeVue
import Button from 'primevue/button';

// Components
import LayoutComponent from './layout.component.vue';
import ProjectFormComponent, { ProjectForm } from './project-form.component.vue';

// Stores
import { useProjectStore } from '../stores/project.store';
import { useClientStore } from '../stores/client.store';
import { useTaskStore } from '../stores/task.store';
import { usePersonStore } from '../stores/person.store';

const $router = useRouter();

const projectStore = useProjectStore();
const clientStore = useClientStore();
const taskStore = useTaskStore();
const personStore = usePersonStore();

const isValid = ref<boolean>(false);
const projectForm = ref<ProjectForm>({
  client: "",
  name: "",
  code: "",
  billable: true,
  hasBudget: true,
  billableType: "project",
  budgetType: "projectHours",
  tasks: [],
  persons: [],
})

async function submit(project: ProjectForm): Promise<void> {
  const client = typeof project.client === "string" ? await clientStore.create({ name: project.client }) : project.client;

  projectStore.create({
    client: client.id,
    name: project.name,
    code: project.code,
    ...project.from && { from: project.from.toISOString() },
    ...project.to && { to: project.to.toISOString() },
    ...project.description && { description: project.description },
    ...project.billable && { billableType: project.billableType },
    ...project.billable && project.billableType === "project" && { rate: project.rate },
    ...project.hasBudget && { budgetType: project.budgetType },
    ...project.hasBudget && project.budgetType === "projectHours" && { budgetHours: project.budgetHours },
    ...project.hasBudget && project.budgetType === "projectCost" && { budgetCost: project.budgetCost },
    tasks: await Promise.all(project.tasks.map(async({ task, rate, budgetHours, budgetCost }) => ({
      task: typeof task === "string" ? (await taskStore.create({ name: task })).id  : task.id,
      ...project.billable && project.billableType === "task" && { rate },
      ...project.hasBudget && project.budgetType === "taskHours" && { budgetHours },
      ...project.hasBudget && project.budgetType === "taskCost" && { budgetCost },
    }))),
    persons: await Promise.all(project.persons.map(async ({ person, rate, budgetHours, budgetCost }) => ({
      person: typeof person === "string" ? (await personStore.create({ name: person })).id  : person.id,
      ...project.billable && project.billableType === "person" && { rate },
      ...project.hasBudget && project.budgetType === "personHours" && { budgetHours },
      ...project.hasBudget && project.budgetType === "personCost" && { budgetCost },
    })))
  });

  $router.push("/projects");
}

</script>