import { defineStore } from "pinia";
import { Surreal } from "surrealdb.js";
import { useTokenStore } from "./token.store";
import { useAuthStore } from "./auth.store";
import { Ref } from "vue";

export type liveRecordsSession = {
  end: () => void;
};

export const useSurrealdbConnectionStore = defineStore(
  "surrealdb-connection",
  () => {
    const tokenStore = useTokenStore();

    const db = new Surreal();
    let isConnected = false;
    async function connect(): Promise<void> {
      const SURREALDB_BASE_URL =
        process.env.SURREALDB_BASE_URL || "http://localhost:8000";
      const SURREALDB_NAMESPACE = process.env.SURREALDB_NAMESPACE || "root";
      const SURREALDB_DATABASE = process.env.SURREALDB_DATABASE || "root";

      await db.connect(SURREALDB_BASE_URL, {
        namespace: SURREALDB_NAMESPACE,
        database: SURREALDB_DATABASE,
      });

      if (tokenStore.token !== null) {
        try {
          await db.authenticate(tokenStore.token);
        } catch (e) {
          tokenStore.clearToken();
        }
      }
    }

    (async () => {
      await connect();
      isConnected = true;
    })();

    async function waitForConnection(): Promise<void> {
      while (!isConnected) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    }

    async function live<T extends Record<string, unknown>>(
      query: string,
      {
        onCreate,
        onUpdate,
        onDelete,
        onInit,
      }: {
        onCreate?: (data: T) => void;
        onUpdate?: (data: T) => void;
        onDelete?: (data: T) => void;
        onInit?: (data: T[]) => void;
      }
    ): Promise<liveRecordsSession> {
      const liveQueryId = await getLiveQuery(query);
      if (onInit) {
        onInit(await db.query<T[]>(query));
      }
      db.listenLive<T>(liveQueryId, (liveResponse) => {
        const { action, result } = liveResponse;
        switch (action) {
          case "CREATE":
            if (onCreate) {
              onCreate(result);
            }
            break;
          case "UPDATE":
            if (onUpdate) {
              onUpdate(result);
            }
            break;
          case "DELETE":
            if (onDelete) {
              onDelete(result);
            }
            break;
        }
      });

      return {
        end: async (): Promise<void> => {
          await db.kill(liveQueryId);
        },
      };
    }

    async function liveRecords<T extends Record<string, unknown>>(
      query: string,
      reference: Ref<T[]>
    ): Promise<liveRecordsSession> {
      reference.value = (await db.query<T[][]>(query))[0];
      const liveQueryId = await getLiveQuery(query);
      db.listenLive<T>(liveQueryId, (liveResponse) => {
        const { action, result } = liveResponse;
        switch (action) {
          case "CREATE":
            reference.value.push(result);
            break;
          case "UPDATE":
            reference.value = reference.value.map((item) => {
              if (item.id === result.id) {
                return result;
              }
              return item;
            });
            break;
          case "DELETE":
            const index = reference.value.findIndex(
              (transaction) => transaction.id === (result as any).id
            );
            reference.value.splice(index, 1);
            break;
        }
      });

      return {
        end: async (): Promise<void> => {
          await db.kill(liveQueryId);
        },
      };
    }

    async function getLiveQuery(query: string): Promise<string> {
      const result = await db.query<string[]>(`LIVE ${query}`);

      if (result.length === 0) {
        throw new Error("No live query ID returned");
      }

      const liveQueryId = result[0];

      return liveQueryId;
    }

    return {
      db,
      connect,
      waitForConnection,
      live,
      liveRecords,
    };
  }
);
