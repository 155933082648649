import { DynamicDialogCloseOptions } from "primevue/dynamicdialogoptions";
import { useDialog } from "primevue/usedialog";
import { resolveComponent } from "vue";

export function openDialog({
  dialog,
  component,
  header,
  data,
  onClose,
}: {
  dialog: ReturnType<typeof useDialog>;
  component: any;
  header: string;
  data?: any;
  onClose?: (options?: DynamicDialogCloseOptions) => void;
}) {
  dialog.open(component, {
    data,
    props: {
      header,
      style: {
        width: "50vw",
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    },
    onClose,
  });
}
