<template>
  <confirm-component @submit="submit" @cancel="cancel" v-model:loading="loading">
    Are you sure you want to remove user {{ user.email }}?
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// Components
import confirmComponent from './confirm.component.vue';

// DTO
import { Company } from '../dto/company';
import { User } from '../dto/user';

// Stores
import { useCompanyStore } from '../stores/company.store';

const dialogRef: any = inject('dialogRef');

const companyStore = useCompanyStore();

const user: User = dialogRef.value.data.user;

const loading = ref(false);

async function submit() {
  if(!companyStore.selectedCompany) {
    return;
  }
  
  loading.value = true;

  await companyStore.removeUser(companyStore.selectedCompany.id, user.id);

  companyStore.reloadCompanies();

  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>