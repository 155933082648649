<template>
  <LayoutComponent>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <h1>Edit Project</h1>
        <form @submit.prevent="submit(projectForm)">
          <project-form-component v-model="projectForm" @isValid="value => isValid = value" :currentProjectCode="code" />
          <div class="flex justify-content-center">
            <Button type="submit" :disabled="!isValid">
              Update Project
            </Button>
          </div>
        </form>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// PrimeVue
import Button from 'primevue/button';

// Components
import LayoutComponent from './layout.component.vue';
import ProjectFormComponent, { ProjectForm } from './project-form.component.vue';

// Stores
import { useProjectStore } from '../stores/project.store';
import { useClientStore } from '../stores/client.store';
import { useTaskStore } from '../stores/task.store';
import { usePersonStore } from '../stores/person.store';

// Utils
import { paramFromRoute } from '../utils';

const $route = useRoute();
const $router = useRouter();

const projectStore = useProjectStore();
const clientStore = useClientStore();
const taskStore = useTaskStore();
const personStore = usePersonStore();

const code = paramFromRoute($route, "code");

const isValid = ref<boolean>(false);
const projectForm = ref<ProjectForm>({
  client: "",
  name: "",
  code: "",
  billable: true,
  hasBudget: true,
  billableType: "project",
  budgetType: "projectHours",
  tasks: [],
  persons: [],
});

onMounted(async () => {
  const project = await projectStore.get(code!);

  projectForm.value = {
      client: { id: project.client.id, name: project.client.name },
      name: project.name,
      code: project.code,
      billable: project.billableType !== undefined,
      hasBudget: project.budgetType !== undefined,
      from: project.startDate ? new Date(project.startDate) : undefined,
      to: project.endDate ? new Date(project.endDate) : undefined,
      description: project.description,
      billableType: project.billableType,
      rate: project.rate,
      budgetType: project.budgetType,
      budgetHours: project.budgetHours,
      budgetCost: project.budgetCost,
      tasks: project.tasks.map(({ task, rate, budgetHours, budgetCost }) => ({
        task: { id: task.id, name: task.name },
        rate,
        budgetHours,
        budgetCost
      })),
      persons: project.persons.map(({ person, rate, budgetHours, budgetCost }) => ({
        person: { id: person.id, name: person.name },
        rate,
        budgetHours,
        budgetCost
      }))
    };
});

async function submit(project: ProjectForm) {
  const client = typeof project.client === "string" ? await clientStore.create({ name: project.client }) : project.client;

  await projectStore.update(code!, {
    client: client.id,
    name: project.name,
    code: project.code,
    startDate: project.from ? project.from.toISOString() : null,
    endDate: project.to ? project.to.toISOString() : null,
    description: project.description ? project.description : null,
    billableType: project.billable ? project.billableType : null,
    rate: project.billable && project.billableType === "project" ? project.rate : null,
    budgetType: project.hasBudget ? project.budgetType : null,
    budgetHours: project.hasBudget && project.budgetType === "projectHours" ? project.budgetHours : null,
    budgetCost: project.hasBudget && project.budgetType === "projectCost" ? project.budgetCost : null,
    tasks: await Promise.all(project.tasks.map(async({ task, rate, budgetHours, budgetCost }) => ({
      task: typeof task === "string" ? (await taskStore.create({ name: task })).id  : task.id,
      ...project.billable && project.billableType === "task" && { rate },
      ...project.hasBudget && project.budgetType === "taskHours" && { budgetHours },
      ...project.hasBudget && project.budgetType === "taskCost" && { budgetCost },
    }))),
    persons: await Promise.all(project.persons.map(async ({ person, rate, budgetHours, budgetCost }) => ({
      person: typeof person === "string" ? (await personStore.create({ name: person })).id  : person.id,
      ...project.billable && project.billableType === "person" && { rate },
      ...project.hasBudget && project.budgetType === "personHours" && { budgetHours },
      ...project.hasBudget && project.budgetType === "personCost" && { budgetCost },
    })))
  });

  $router.push("/projects");
}
</script>