<template>
  <layout-component>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2">
        <div class="flex justify-content-between align-items-center mb-3">
          <div>
            <router-link :to="`/track/${queryDateFormat(previousDate(setDate))}`">
              <Button icon="pi pi-chevron-left" class="track__date-button--previous" />
            </router-link>
            <router-link :to="`/track/${queryDateFormat(nextDate(setDate))}`">
              <Button icon="pi pi-chevron-right" class="track__date-button--next" />
            </router-link>
            <router-link v-if="!isToday(setDate, today)" :to="`/track/${queryDateFormat(today)}`">
              <Button link label="Return to today" />
            </router-link>
          </div>
          <div class="flex gap-3">
            <template v-if="authStore.isAdmin">
              <div>
                Show tracks for:
                <Dropdown style="width: 150px" v-model="trackerViewFor" :options="trackerViewOptions" optionLabel="label" optionValue="value" />
              </div>
            </template>
            <Button icon="pi pi-plus" icon-pos="right" label="Track Time" @click="addTrack" />
          </div>
        </div>
        <TabMenu :model="weekDayItems" :activeIndex="setWeekDay">
          <template #item="{ item, index, props }">
            <router-link v-slot="{ href, navigate }" :to="`${queryDateFormat(dateFromWeekDay(index))}`" v-if="!item.isTotal" custom>
              <a :href="href" v-bind="props.action" @click="navigate" :class="{ 'track__week-tab__link--today': isToday(dateFromWeekDay(index), today) }">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    {{ item.label }}
                  </div>
                  <div class="col-6 flex align-items-center">
                    <span class="track__week-tab__date">{{ dateFormat(dateFromWeekDay(index), "dd/mm") }}</span>
                  </div>
                  <div class="col-12">
                    <span class="track__week-tab__total"><track-total-duration-component :tracks="weekTracks" :date="dateFromWeekDay(index)" :key="weekNumberKey" /></span>
                  </div>
                </div>
              </a>
            </router-link>
            <div v-else class="text-right p-3 w-full" v-bind="props.action">
              <b>Week total</b><br />
              <track-week-duration-component :tracks="weekTracks" :key="weekNumberKey" :startDate="dateFromWeekDay(0)" :endDate="dateFromWeekDay(6)" />
            </div>
          </template>
        </TabMenu>
        <track-list-component :view-for="trackerViewFor" :date="setDate" :key="`${queryDateFormat(setDate)}-${trackerViewFor}`" />
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

// PrimeVue
import { useDialog } from 'primevue/usedialog';
import Button from 'primevue/button';
import TabMenu from 'primevue/tabmenu';
import { MenuItem } from 'primevue/menuitem';
import Dropdown from 'primevue/dropdown';

// Components
import LayoutComponent from './layout.component.vue';
import TrackListComponent from './track-list.component.vue';
import TrackCreateComponent from './track-create.component.vue';
import TrackTotalDurationComponent from './track-total-duration.component.vue';
import TrackWeekDurationComponent from './track-week-duration.component.vue';

// Stores
import { useAuthStore } from '../stores/auth.store';
import { useTrackStore } from '../stores/track.store';
import { liveRecordsSession } from '../stores/surrealdb-connection.store';

// Utils
import { paramFromRoute, dateFormat, isToday } from '../utils';

// DTO's
import { Track } from '../dto/track';

// Consts
import { weekDays } from '../consts/weekdays';

const $route = useRoute();
const dialog = useDialog();

const authStore = useAuthStore();
const trackStore = useTrackStore();

const today = computed<Date>(() => new Date(dateFormat(new Date(), "YYYY-MM-DD")));

const setDate = computed<Date>(() => {
  const dateParam = paramFromRoute($route, 'date');
  if(dateParam !== undefined) {
    return new Date(dateParam);
  }
  
  throw new Error('Date param is required');
});
const setWeekDay = computed<number>(() => setDate.value.getUTCDay() === 0 ? 6 : setDate.value.getUTCDay() - 1);
const weekNumberKey = computed<string>(() => `${weekDateFormat(setDate.value)}-${trackerViewFor.value}`);

const trackerViewOptions = [
  { label: 'Me', value: 'me' },
  { label: 'Team', value: 'all' },
];
const trackerViewFor = ref<"me" | "all">("me");

const weekDayItems: MenuItem[] = [
  { label: weekDays[0].short },
  { label: weekDays[1].short },
  { label: weekDays[2].short },
  { label: weekDays[3].short },
  { label: weekDays[4].short },
  { label: weekDays[5].short },
  { label: weekDays[6].short },
  { isTotal: true, style: "border: 1px solid #000", class: "track__week-tab--total" }
];

const weekTracks = ref<Track[]>([]);

let listner: liveRecordsSession | undefined;

watch(weekNumberKey, async () => {
  if(listner) {
    listner.end();
  }
  await updateLiveListner();
});


onMounted(async () => {
  await updateLiveListner();
});

onUnmounted(async () => {
  await endLiveListner();
});

async function updateLiveListner() {
  endLiveListner();
  listner = await trackStore.list(dateFromWeekDay(0), dateFromWeekDay(6), trackerViewFor.value, weekTracks);
}
async function endLiveListner() {
  if(listner) {
    await listner.end();
  }
}

function nextDate(date: Date): Date {
  const nextDate = new Date(date);
  nextDate.setUTCDate(nextDate.getUTCDate() + 1);
  return nextDate;
}

function previousDate(date: Date): Date {
  const previousDate = new Date(date);
  previousDate.setUTCDate(previousDate.getUTCDate() - 1);
  return previousDate;
}

function dateFromWeekDay(weekDay: number): Date {
  const date = new Date(setDate.value);
  const adjustDays = weekDay - setWeekDay.value;

  date.setUTCDate(date.getUTCDate() + adjustDays);

  return date;
}

function queryDateFormat(date: Date): string {
  return date.toISOString().split('T')[0];
}
function weekDateFormat(date: Date): string {
  return dateFormat(date, "YYYY-W");
}

function addTrack() {
  dialog.open(TrackCreateComponent, {
    data: {
      date: setDate.value
    },
    props: {
      header: "Create Track",
      style: {
        width: "50vw"
      },
      breakpoints: { "960px": "90%", "640px": "100vw" },
      modal: true,
      closeOnEscape: true,
    }
  });
}
</script>
<style lang="scss" scoped>
  .track {
    &__date-button {
      &--previous {
        border-radius: 6px 0px 0px 6px;
      }
      &--next {
        border-radius: 0px 6px 6px 0px;
      }
    }
    &__week-tab {
      &__link {
        &--today {
          color: var(--orange-400);
        }
      }
      &__date {
        font-size: 0.8rem;
        color: #aaa;
      }
      &__total {
        font-size: 0.8rem !important;
        color: #aaa;
        &::v-deep i {
          font-size: 0.8rem;
        }
      }
    }
  }
</style>
<style lang="scss">
  .track__week-tab--total {
    display: flex;
    flex-grow: 1;
    justify-content: end;
  }
</style>