<template>
  <form @submit.prevent="submit(task.id, taskForm)">
    <TaskFormComponent v-model="taskForm" @isValid="value => isValid = value" />
    <div class="flex justify-content-end gap-3">
      <Button severity="secondary" @click="cancel" :disabled="saving">Cancel</Button>
      <Button type="submit" label="Update" :loading="saving" :disabled="!isValid" />
    </div>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// PrimeVue
import Button from 'primevue/button';

// Components
import TaskFormComponent, { TaskForm } from './task-form.component.vue';

// Stores
import { useTaskStore } from '../stores/task.store';

// DTO's
import { Task } from '../dto/task';

const dialogRef: any = inject('dialogRef');

const task: Task = dialogRef.value.data.task;
const taskStore = useTaskStore();

const saving = ref(false);
const isValid = ref(false);

const taskForm = ref<TaskForm>({
  name: "",
  defaultRate: undefined
});

onMounted(() => {
  if (task) {
    taskForm.value.name = task.name;
    taskForm.value.defaultRate = task.defaultRate;
  }
})

async function submit(taskId: string, task: TaskForm) {
  saving.value = true;
  await taskStore.update(taskId, {
    name: taskForm.value.name.trim(),
    ...(taskForm.value.defaultRate ? { defaultRate: taskForm.value.defaultRate } : {})
  });
  close();
}

function cancel() {
  close();
}

function close() {
  dialogRef.value.close();
}
</script>