<template>
  <template v-if="loading">
    <div class="w-100 flex justify-content-center">
      <i class="pi pi-spin pi-spinner" style="font-size: 2.5rem" />
    </div>
  </template>
  <template v-else>
    <confirm-component @submit="submit" @cancel="cancel" v-model:loading="submitLoading">
      Are you sure you want to delete this track?
    </confirm-component>
  </template>
</template>
<script lang="ts" setup>
// Libs
import { inject, onMounted, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useTrackStore } from '../stores/track.store';
import { Track } from '../dto/track';

const dialogRef: any = inject('dialogRef');

const trackStore = useTrackStore();

const loading = ref(false);
const submitLoading = ref(false);

const track: Track = dialogRef.value.data.track;

onMounted(async () => {
  loading.value = true;
  loading.value = false;
});

async function submit() {
  submitLoading.value = true;
  await trackStore.remove(track.id);
  dialogRef.value.close();
}

function cancel() {
  dialogRef.value.close();
}
</script>