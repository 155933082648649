<template>
  <DataTable
    v-model:expanded-rows="expandedRows"
    :value="groupedTracks"
    class="m-0 p-0"
    :pt="{
      rowExpansionCell: {
        style: {
          padding: '0px',
        },
      },
      ...(!isRoot ? {
        root: {
        style: {
          padding: '0px',
        },
      },
      headerRow: {
        style: {
          display: 'none',
        },
      },
      } : {})
    }"
  >
    <Column :expander="isExpandable" style="width: 20%">
      <template #body="{ data, rowTogglerCallback }">
        <template v-if="isExpandable">
          <Button :icon="`pi pi-chevron-${isRowExpanded(data.id) ? 'down' : 'right'}`" style="padding: 0px; width: auto" link aria-label="Expander" @click="rowTogglerCallback" />
        </template>
      </template>
    </Column>
    <Column field="name" style="width: 20%">
      <template #header>
        <template v-if="groupBy === 'client'">Client</template>
        <template v-if="groupBy === 'project'">Project</template>
        <template v-else-if="groupBy === 'task'">Task</template>
        <template v-else-if="groupBy === 'person'">Person</template>
      </template>
    </Column>
    <Column field="hours" header="Hours" style="width: 15%">
      <template #body="{ data }"> {{ round(data.hours, 2) }}h </template>
    </Column>
    <Column field="cost" header="Cost" style="width: 15%">
      <template #body="{ data }">
        <template v-if="data.cost">
          {{ swedishWholeKronerFormat(data.cost) }}
        </template>
        <template v-else>
          <i>-</i>
        </template>
      </template>
    </Column>
    <template #expansion="{ data }" v-if="hasDefaultSlot">
      <slot :group-id="data.id" />
    </template>
  </DataTable>
</template>
<script lang="ts" setup>
// Libs
import { defineProps, computed, ref, useSlots } from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

// DTO's
import { Track } from '../dto/track';
import { hoursBetweenDates } from '../utils';

// Utils
import { swedishWholeKronerFormat, round } from '../utils';

const $slots = useSlots();
const $props = withDefaults(defineProps<{
  groupBy: "client" | "project" | "task" | "person";
  tracks: Track[];
  group: { id: string, name: string}[];
  isRoot?: boolean
}>(), {
  isRoot: true
});

const hasDefaultSlot = computed(() => !!$slots.default);
const isExpandable = computed(() => hasDefaultSlot.value);

const groupedTracks = computed(() => {
  return $props.group.map((group) => {
    let tracks: Track[];
    if($props.groupBy === 'client') {
      tracks = $props.tracks.filter((track) => track.project.client.id === group.id);
    } else if($props.groupBy === 'project') {
      tracks = $props.tracks.filter((track) => track.project.id === group.id);
    } else if($props.groupBy === 'task') {
      tracks = $props.tracks.filter((track) => track.task.id === group.id);
    } else {
      tracks = $props.tracks.filter((track) => track.person.id === group.id);
    }
    return {
      ...group,
      ...getAccumulatedDataForTracks(tracks),
    };
  });
});

const expandedRows = ref<any[]>([]);

function isRowExpanded(rowId: string): boolean {
  return expandedRows.value.find(row => row.id === rowId);
}

function getAccumulatedDataForTracks(tracks: Track[]): {
  hours: number;
  cost: number;
} {
  return {
    hours: tracks.reduce(
      (acc: number, track: Track) =>
        acc +
        (track.endDate ? hoursBetweenDates(track.startDate, track.endDate) : 0),
      0
    ),
    cost: tracks.reduce((acc, track) => {
      if (track.endDate) {
        let trackHours = hoursBetweenDates(track.startDate, track.endDate);
        let cost = 0;
        const taskMetaData = track.project.tasks.find(
          (task) => task.task === track.task.id
        );
        const personMetaData = track.project.persons.find(
          (person) => person.person === track.person.id
        );
        if (track.project.billableType === "task" && taskMetaData?.rate) {
          cost = trackHours * taskMetaData.rate;
        } else if (
          track.project.billableType === "person" &&
          personMetaData?.rate
        ) {
          cost = trackHours * personMetaData.rate;
        } else if (
          track.project.billableType === "project" &&
          track.project.rate
        ) {
          cost = trackHours * track.project.rate;
        }

        return acc + cost;
      }
      return acc;
    }, 0),
  };
}


</script>