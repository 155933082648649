import { defineStore } from "pinia";
import {
  liveRecordsSession,
  useSurrealdbConnectionStore,
} from "./surrealdb-connection.store";
import { useCompanyStore } from "./company.store";
import { Client, ClientCreate, ClientUpdate } from "../dto/client";
import { ref, watch } from "vue";

export type StoreClient = Client & { numberOfProjects: number };

export const useClientStore = defineStore("client", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const companyStore = useCompanyStore();

  const clients = ref<StoreClient[]>([]);

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => companyStore.selectedCompany,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<StoreClient>(
      `
      select
        *,
        array::len(SELECT * FROM project WHERE client = $parent.id) AS numberOfProjects
      from client
      WHERE company.id = "${companyStore.selectedCompany?.id}"
    `,
      clients
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    setLiveQuery();
  })();

  async function list(): Promise<Client[]> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }

    const result = await surrealdbStore.db.query<Client[][]>(`
      SELECT
        *
      FROM client
      WHERE
        company = ${companyId}
    `);
    return result[0] || [];
  }

  async function create(client: ClientCreate): Promise<Client> {
    const companyId = companyStore.selectedCompany?.id;
    if (!companyId) {
      throw new Error("No Company currently selected");
    }
    const result = await surrealdbStore.db.create<
      Client,
      ClientCreate & { company: string }
    >("client", {
      company: companyId,
      ...client,
    });
    if (result.length === 0) {
      throw new Error("Failed to create client");
    }

    const { id, name } = result[0];

    return {
      id,
      name,
    };
  }

  async function update(id: string, client: ClientUpdate): Promise<void> {
    await surrealdbStore.db.query(
      `update client set name = $name where id = $id`,
      { id, ...client }
    );
  }

  async function remove(id: string): Promise<void> {
    await surrealdbStore.db.query(`delete from client where id = $id`, { id });
  }

  async function clientHasProjects(clientId: string): Promise<boolean> {
    return (
      (
        await surrealdbStore.db.query<any[][]>(
          `
      SELECT * FROM project WHERE client = $id
    `,
          { id: clientId }
        )
      )[0].length > 0
    );
  }

  return { clients, list, create, update, remove, clientHasProjects };
});
