<template>
  <LayoutComponent>
    <div class="grid">
      <div class="col-12 lg:col-6 lg:col-offset-3 xl:col-4 xl:col-offset-4 gap-2 flex flex-column gap-2">
        <h1>Select Company</h1>
        <Button v-for="company of companyStore.companies" :label="company.name" icon="pi pi-arrow-right" icon-pos="right" class="w-full" severity="contrast" outlined @click="selectCompany(company.id)" />
        <router-link to="/company/create" class="w-full">
          <Button label="Create Company" icon="pi pi-plus" icon-pos="right" class="w-full" />
        </router-link>
      </div>
    </div>
  </LayoutComponent>
</template>
<script lang="ts" setup>
// Libs
import { useRouter } from "vue-router";

// PrimeVue
import Button from "primevue/button";

// Stores
import { useCompanyStore } from "../stores/company.store"

// Components
import LayoutComponent from './layout.component.vue';

const companyStore = useCompanyStore();
const $router = useRouter();


function selectCompany(companyId: string): void {
  companyStore.setSelectedCompany(companyId);
  $router.replace("/track");
}
</script>