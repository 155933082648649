<template>
  <Chart type="line" :data="chartData" :options="chartOptions" class="h-30rem" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import Chart from 'primevue/chart';

import { ProjectWithTrack, ProjectTrack } from '../stores/project.store';
import { hoursBetweenDates } from '../utils';

const $props = defineProps<{
  project: ProjectWithTrack;
}>();

const chartData = computed(() => {
  const data = tracksGroupedByWeek.value;
  return {
    labels: Object.keys(data),
    datasets: [{
      label: $props.project.name,
      data: [5,2,3],
      fill: false,
      borderColor: '#42A5F5'
    }]
  };
});
const chartOptions = {
  animation: false,
  plugins: {
    tooltip: {
      mode: "nearest",
      intersect: false
    }
  }
};

const days = daysBetweenDates($props.project.startDate, $props.project.endDate);

const tracksGroupedByWeek = computed(() => {
  return $props.project.tracks.filter(track => track.endDate !== undefined).reduce((acc, track) => {
    const week = getWeekNumber(track.startDate);
    if (!acc[week]) {
      acc[week] = [];
    }
    acc[week].push(track);
    return acc;
  }, {} as Record<number, ProjectTrack[]>);
});

function getWeekNumber(date: Date | string): number {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
  return weekNo;
}

</script>
<style lang="scss" scoped>

</style>