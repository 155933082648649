<template>
  <confirm-component @submit="submit" @cancel="cancel" v-model:loading="loading">
    Are you sure you want to delete this project?
  </confirm-component>
</template>
<script lang="ts" setup>
// Libs
import { inject, ref } from 'vue';

// Components
import ConfirmComponent from './confirm.component.vue';

// Stores
import { useProjectStore } from '../stores/project.store';

const dialogRef: any = inject('dialogRef');

const projectStore = useProjectStore();

const loading = ref(false);

const projectCode: string = dialogRef.value.data.projectCode;

async function submit() {
  loading.value = true;
  await projectStore.remove(projectCode);
  dialogRef.value.close();
}

function cancel() {
  dialogRef.value.close();
}
</script>