<template>
  <form @submit.prevent="submit(form)" data-test="invite-form">
    <div class="grid">
      <div :class="{ 'col-6': authStore.isOwner, 'col-9': !authStore.isOwner }">
        <form-input-component label="Email" input-id="inviteEmail" :warning="invalid === 'user_exist'">
          <InputText class="w-full" type="email" id="inviteEmail" v-model="form.email" :disabled="loading" />
          <template #warning>
            User with email already exists.
            <router-link to="/forgot-password">
              Click here if you forgot your password
            </router-link>
          </template>
        </form-input-component>
      </div>
      <div class="col-3" v-if="authStore.isOwner">
        <form-input-component label="Role" input-id="inviteRole">
          <Dropdown v-model="form.role" :options="roles" optionLabel="label" optionValue="value" :disabled="loading" />
        </form-input-component>
      </div>
      <div class="col-3" style="padding-top: 2.3rem">
        <Button type="submit" label="Invite" icon="pi pi-send" iconPos="right" class="w-full" :loading="loading" :disabled="!isValid" />
      </div>
    </div>
    <Message v-if="success" severity="success" @close="success = false">
      Invitation sent to {{ form.email }}
    </Message>
    <Message v-else-if="invalid " severity="error" @close="invalid = false">
      Something went wrong, please try again
    </Message>
  </form>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';

// PrimeVue
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Dropdown from 'primevue/dropdown';

// Components
import FormInputComponent from './form-input.component.vue';

// Stores
import { useCompanyStore } from '../stores/company.store';
import { usePersonStore } from '../stores/person.store';

// DTO's
import { User } from '../dto/user';
import { useAuthStore } from '../stores/auth.store';
import { getRecaptcha } from '../utils';

const reCaptcha = useReCaptcha();

const companyStore = useCompanyStore();
const personStore = usePersonStore();
const authStore = useAuthStore();

const loading = ref(false);
const invalid = ref<boolean | 'user_exist'>(false);
const success = ref(false);

const roles = computed(() =>[
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
]);

const form = ref<{
  email: string,
  role: 'user' | 'admin',
}>({
  email: '',
  role: 'user',
});

const isValid = computed(() => form.value.email.trim() !== "");

async function submit({ email, role }: { email: string, role: 'user' | 'admin' }): Promise<void> {
  if(!companyStore.selectedCompany) {
    return;
  }

  if(companyStore.selectedCompany.users.find(user => user.user.email === email)) {
    invalid.value = 'user_exist';
    return;
  }
  
  loading.value = true;
  
  try {
    // Load captcha
    const captchaCode = await getRecaptcha(reCaptcha!);

    // Invite user
    const { company, userId } = await companyStore.invite({
      companyId: companyStore.selectedCompany.id,
      captcha: captchaCode,
      email,
      role
    });

    // Get invited user from updated company
    const user: User = company.users.find(user => user.user.id === userId)!.user;
    
    // Create person attached to invited user and company
    await personStore.create({
      name: user.email,
      user: userId,
    });
    
    // Set selected company
    companyStore.reloadCompanies();
  } catch (error) {
    if(error.message === 'User already invited') {
      invalid.value = 'user_exist';
    } else {
      invalid.value = true;
    }
  }

  loading.value = false;
}
</script>