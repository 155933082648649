<template>
  <DataTable v-model:expanded-rows="expandedRows" :value="tasks" class="m-0 p-0" :pt="{
          rowExpansionCell: {
            style: {
              padding: '0px'
            }
          }
        }">
            <Column expander style="width: 20%" />
            <Column field="task.name" header="Task" style="width: 20%" />
            <Column field="hours" header="Hours" style="width: 15%">
              <template #body="{ data }">
                {{ round(data.hours, 2) }}h
              </template>
            </Column>
            <Column field="cost" header="Cost" style="width: 15%">
              <template #body="{ data }">
                <template v-if="data.cost">
                  {{ swedishWholeKronerFormat(data.cost) }}
                </template>
                <template v-else>
                  <i>-</i>
                </template>
              </template>
            </Column>
            <Column field="rate" header="Rate" style="width: 15%">
              <template #body="{ data }">
                <template v-if="project.billableType === 'task'">
                  {{ swedishWholeKronerFormat(data.rate) }}/h
                </template>
                <template v-else>
                  <i>-</i>
                </template>
              </template>
            </Column>
            <Column field="budget" header="Budget" style="width: 15%">
              <template #body="{ data }">
                <template v-if="project.budgetType === 'taskCost'">
                  {{ swedishWholeKronerFormat(data.budget) }}
                </template>
                <template v-else-if="project.budgetType === 'taskHours'">
                  {{ round(data.budget, 0) }}h
                </template>
                <template v-else>
                  <i>-</i>
                </template>
              </template>
            </Column>
            <template #expansion="{ data }">
              <DataTable :value="data.persons" :pt="{
                root: {
                  style: {
                    padding: '0px'
                  }
                },
                headerRow: {
                  style: {
                    display: 'none'
                  }
                }
              }">
                <Column field="person.name" style="width: 40%; padding-left: calc(20% + 1rem)" />
                <Column field="hours" style="width: 15%">
                  <template #body="{ data }">
                    {{ round(data.hours, 2) }}h
                  </template>
                </Column>
                <Column field="cost" style="width: 15%">
                  <template #body="{ data }">
                    <template v-if="data.cost !== undefined">
                      {{ swedishWholeKronerFormat(data.cost) }}
                    </template>
                    <template v-else>
                      <i>-</i> {{ data.cost}}
                    </template>
                  </template>
                </Column>
                <Column field="rate" style="width: 15%">
                  <template #body="{ data }">
                    <template v-if="data.rate">
                      {{ swedishWholeKronerFormat(data.rate) }}/h
                    </template>
                    <template v-else>
                      <i>-</i>
                    </template>
                  </template>
                </Column>
                <Column field="budget" style="width: 15%">
                  <template #body>
                    -
                  </template>
                </Column>
              </DataTable>
            </template>
  </DataTable>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ProjectWithTrack, ProjectTrack } from '../stores/project.store';
import { hoursBetweenDates, swedishWholeKronerFormat, round } from '../utils';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

const $props = defineProps<{
  project: ProjectWithTrack;
}>();

const expandedRows = ref<any[]>([]);

const tasks = computed(() => {
  if (!$props.project) {
    return [];
  }

  return $props.project.tasks.map((task) => {
    const tracks = getTrackFor($props.project.tracks, { taskId: task.task.id });
    return {
    ...task,
    hours: tracks.reduce((acc, track) => {
      if (track.endDate) {
        return acc + hoursBetweenDates(track.startDate, track.endDate);
      }
      return acc;
    }, 0) || 0,
    ...($props.project.billableType ? {
    cost: tracks.reduce((acc, track) => {
      if (track.endDate) {
        let value = hoursBetweenDates(track.startDate, track.endDate);
        if($props.project.billableType === "task" && task.rate) {
          value *= task.rate;
        } else if($props.project.billableType === "person") {
          const person = $props.project.persons.find(person => person.person.id === track.person.id);
          value *= (person?.rate || 0);
        } else if($props.project.billableType === "project" && $props.project.rate) {
          value *= $props.project.rate;
        }

        return acc + value;
      }
      return acc;
    }, 0) || 0,
  } : {}),
    ...($props.project.budgetType === "taskHours" && task.budgetHours) && { budget: task.budgetHours },
    ...($props.project.budgetType === "taskCost" && task.budgetCost) && { budget: task.budgetCost },
    persons: $props.project.persons.map((person) => {
      const taskPersonTracks = getTrackFor($props.project.tracks, { taskId: task.task.id, personId: person.person.id });
      return {
      ...person,
      hours: taskPersonTracks.reduce((acc, track) => {
        if (track.endDate) {
          return acc + hoursBetweenDates(track.startDate, track.endDate);
        }
        return acc;
      }, 0) || 0,
      ...($props.project.billableType ? {
      cost: taskPersonTracks.reduce((acc, track) => {
        if (track.endDate) {
          let value = hoursBetweenDates(track.startDate, track.endDate);
          if($props.project.billableType === "person" && person.rate) {
            value *= person.rate;
          } else if($props.project.billableType === "task") {
            const task = $props.project.tasks.find(task => task.task.id === track.task.id);
            value *= (task?.rate || 0);
          } else if($props.project.billableType === "project" && $props.project.rate) {
            value *= $props.project.rate;
          }

          return acc + value;
        }
        return acc;
      }, 0) || 0,
    } : {}),
    }; }),
  }; });
});

function getTrackFor(tracks: ProjectTrack[], { personId, taskId }: { personId?: string, taskId?: string } = {}): ProjectTrack[] {
  return tracks.filter(track => {
    if (personId && track.person.id !== personId) {
      return false;
    }

    if (taskId && track.task.id !== taskId) {
      return false;
    }

    return true;
  });
}
</script>