<template>
  <layout-component v-if="project">
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2">
        <div class="flex justify-content-between align-items-center mb-3">
          <h1>{{ project.name }}</h1>
        </div>
        <TabMenu :model="items">
          <template #item="{ item, props }">
              <router-link v-slot="{ href, navigate }" :to="item.route" custom>
                  <a :href="href" v-bind="props.action" @click="navigate">
                      <span v-bind="props.icon" />
                      <span v-bind="props.label">{{ item.label }}</span>
                  </a>
              </router-link>
          </template>
        </TabMenu>
        <router-view :project="project" />
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import TabMenu from 'primevue/tabmenu';
import { MenuItem } from 'primevue/menuitem';

import LayoutComponent from './layout.component.vue';
import { ProjectWithTrack, useProjectStore } from '../stores/project.store';
import { paramFromRoute } from '../utils';

const $route = useRoute();

const projectStore = useProjectStore();

const items = ref<MenuItem[]>([
  { label: 'Graph', icon: 'pi pi-home', route: { name: 'projects.view.graph', query: { code: $route.params.code } } },
  { label: 'Tasks', icon: 'pi pi-home', route: { name: 'projects.view.tasks', param: { code: $route.params.code } } },
  { label: 'Persons', icon: 'pi pi-home', route: { name: 'projects.view.persons', param: { code: $route.params.code } } },
]);

const code = computed<string>(() => {
  const code = paramFromRoute($route, "code");
  if (!code) {
    throw new Error("No code in route");
  }
  return code;
});

const project = ref<ProjectWithTrack>();

onMounted(async () => {
  project.value = await projectStore.getWithTracks(code.value)
});
</script>