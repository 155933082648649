import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import {
  liveRecordsSession,
  useSurrealdbConnectionStore,
} from "./surrealdb-connection.store";
import {
  Company,
  CompanyCreate,
  CompanyUpdate,
  CompanyUserRole,
} from "../dto/company";
import { User } from "../dto/user";
import { useAuthStore } from "./auth.store";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTokenStore } from "./token.store";

const API_BASE_URL = process.env.API_BASE_URL || "http://localhost:3000";

export const useCompanyStore = defineStore("company", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  const authStore = useAuthStore();
  const tokenStore = useTokenStore();

  const $router = useRouter();

  const selectedCompanyInStorage = useLocalStorage<string | undefined>(
    "selectedCompany",
    undefined
  );
  const selectedCompany = computed<Company<User> | undefined>(() => {
    return (
      companies.value.find(
        (company) => company.id === selectedCompanyInStorage.value
      ) || undefined
    );
  });

  if (!authStore.isAuth) {
    selectedCompanyInStorage.value = undefined;
  }

  let isCompaniesLoaded = false;
  const companies = ref<Company<User>[]>([]);

  let liveQuery: liveRecordsSession | undefined;
  watch(
    () => authStore.me,
    () => setLiveQuery()
  );

  async function setLiveQuery() {
    if (liveQuery) {
      liveQuery.end();
    }

    liveQuery = await surrealdbStore.liveRecords<Company<User>>(
      `
      SELECT
        *,
        (
          SELECT
            in.* as user,
            role,
            active
          FROM <-memberOf.*
        ) as users
      FROM company
`,
      companies
    );
  }

  (async () => {
    await surrealdbStore.waitForConnection();
    await setLiveQuery();
    isCompaniesLoaded = true;
  })();

  async function waitForCompaniesToBeloaded(): Promise<void> {
    while (!isCompaniesLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  async function get(companyId: string): Promise<Company<User>> {
    const company = (
      await surrealdbStore.db.query<Company<User>[][]>(
        `
      SELECT
        *,
        (
          SELECT
            in.* as user,
            role,
            active
          FROM <-memberOf.*
        ) as users
      FROM company
      WHERE id = $companyId
    `,
        {
          companyId,
        }
      )
    )[0][0];

    if (!company) {
      throw new Error("Company not found");
    }

    return company;
  }

  async function reloadCompanies(): Promise<void> {
    await setLiveQuery();
  }

  function setSelectedCompany(companyId: string): void {
    selectedCompanyInStorage.value = companyId;
  }

  function clearSelectedCompany(): void {
    selectedCompanyInStorage.value = undefined;
    $router.push("/select-company");
  }

  async function create({
    captcha,
    company,
  }: {
    captcha: string;
    company: CompanyCreate;
  }): Promise<Company<User>> {
    const response = await fetch(`${API_BASE_URL}/company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-recaptcha-token": captcha,
        "x-auth-token": tokenStore.token,
      },
      body: JSON.stringify(company),
    });

    const data = await response.json();
    if (response.status !== 200) {
      throw new Error(data?.message);
    }

    return get(data.company.id);
  }

  async function update(
    companyId: string,
    company: CompanyUpdate
  ): Promise<Company<User>> {
    await surrealdbStore.db.merge<Company, CompanyUpdate>(companyId, company);

    return get(companyId);
  }

  async function updateUser(
    companyId: string,
    userId: string,
    role: CompanyUserRole
  ): Promise<Company<User>> {
    await surrealdbStore.db.query(
      `
      UPDATE memberOf
      SET role = $role
      WHERE
        out = $companyId AND
        in = $userId
    `,
      {
        companyId,
        userId,
        role,
      }
    );

    return get(companyId);
  }

  async function removeUser(
    companyId: string,
    userId: string
  ): Promise<Company<User>> {
    await surrealdbStore.db.query(
      `
      DELETE FROM memberOf
      WHERE
        out = $companyId AND
        in = $userId
    `,
      {
        companyId,
        userId,
      }
    );

    return get(companyId);
  }

  async function invite({
    companyId,
    captcha,
    email,
    role,
  }: {
    companyId: string;
    captcha: string;
    email: string;
    role: "user" | "admin";
  }): Promise<{ company: Company<User>; userId: string }> {
    const response = await fetch(
      `${API_BASE_URL}/company/${companyId}/invite`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": captcha,
          "x-auth-token": tokenStore.token,
        },
        body: JSON.stringify({
          user: {
            email,
            role,
          },
        }),
      }
    );

    const data = await response.json();
    if (response.status !== 200) {
      throw new Error(data?.message);
    }

    return {
      company: await get(companyId),
      userId: data.user.id,
    };
  }

  async function transferOwnership({
    captcha,
    companyId,
    userId,
  }: {
    captcha: string;
    companyId: string;
    userId: string;
  }): Promise<Company<User>> {
    const response = await fetch(
      `${API_BASE_URL}/company/${companyId}/user/${userId}/transfer-ownership`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": captcha,
          "x-auth-token": tokenStore.token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error((await response.json()).message);
    }

    return get(companyId);
  }

  async function removeCompany({
    captcha,
    companyId,
  }: {
    captcha: string;
    companyId: string;
  }) {
    const response = await fetch(`${API_BASE_URL}/company/${companyId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-recaptcha-token": captcha,
        "x-auth-token": tokenStore.token,
      },
    });

    if (response.status !== 200) {
      throw new Error((await response.json()).message);
    }
  }

  watch(
    () => authStore.isAuth,
    () => {
      selectedCompanyInStorage.value = undefined;
    }
  );

  return {
    companies,
    reloadCompanies,
    waitForCompaniesToBeloaded,
    get,
    create,
    update,
    updateUser,
    removeUser,
    invite,
    transferOwnership,
    removeCompany,
    selectedCompany,
    setSelectedCompany,
    clearSelectedCompany,
  };
});
